import React from 'react';
import SkeletonCard from "./Skeleton";

export default function BlankWindow() {
    return (
        <div className="skele_card">
            <SkeletonCard />
        </div>
    )
}
