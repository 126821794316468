// Chat message holder component (switch case for image, document, video or text)

import React, { Component, createRef, forwardRef } from "react";
import ClickImageModal from "./ClickImageModal";
import { Menu, Dropdown, Row, Col } from "antd";
import anchorme from "anchorme";
import firebase from "../Firebase";
import { RiArrowDropDownLine } from "react-icons/ri";
import {
    statusIcons,
	chatMessages
  } from "../utils/contants";
  
import dayjs from "dayjs";

const MessageText = ({ input }) => (
	<span
		dangerouslySetInnerHTML={{
			__html: anchorme({
				input,
				options: {
					attributes: {
						target: "_blank",
					},
				},
			}),
		}}></span>
);

export class Message extends Component {
	constructor(props) {
		super(props);
		this.innerRef = this.props.innerRef ? createRef() : null;
		this.state = {
			visible: false,
			status: "",
			listening: false,
		};
		this.dbRef = firebase.database().ref(`${chatMessages}/${this.props.chatId}/${this.props.messageKey}`);
		this.clickImageModalRef = React.createRef();
	}

	componentDidMount() {
		if (this.props.curr_chat.status && this.props.curr_chat.status !== "read" && this.props.fromMe) {
			this.dbRef.on("value", (snap) => {
				const status = snap.val().status;
				this.setState({ status, listening: true });
				if (status === "read") {
					this.removeStatusListener();
				}
			});
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.isLastMessageByUs !== this.props.isLastMessageByUs && this.state.listening) {
			const isLastMessageByUs = this.props.isLastMessageByUs;

			if ((this.state.status === "delivered" || this.state.status === "enqueued") && !isLastMessageByUs) {
				this.removeStatusListener();
			}
		}
	}

	removeStatusListener = () => {
		this.dbRef.off();
		this.setState({ listening: false });
	};

	handleImage(src) {
		this.clickImageModalRef.current.visibleModal();
	}

	getRequiredMessageType = (curr_chat) => {
		switch (curr_chat.type) {
			case "image":
				return (
					<>
						<img
							id="inline_img"
							src={curr_chat.message}
							onClick={() => this.handleImage(curr_chat.message)}
							alt="new"
						/>
						<ClickImageModal ref={this.clickImageModalRef} imageSRC={curr_chat.message} />
						<div>{curr_chat.caption}</div>
					</>
				);

			case "document":
				return (
					<a href={curr_chat.message} without rel="noopener noreferrer" target="_blank">
						{curr_chat.caption}.pdf
					</a>
				);

			case "file":
				return (
					<a href={curr_chat.message} without rel="noopener noreferrer" target="_blank">
						{curr_chat.caption}.pdf
					</a>
				);

			case "audio":
				return (
					<figure>
						<figcaption>Audio:</figcaption>
						<audio controls src={curr_chat.message}>
							Your browser does not support the
							<code>audio</code> element.
						</audio>
					</figure>
				);

			case "video":
				return <video title="NewVideo" controls height="200px" width="300px" src={curr_chat.message}></video>;

			default:
				return (
					<div style={{ display: "flex", justifyContent: "space-between", whiteSpace: "break-spaces" }}>
						<MessageText input={curr_chat.message} />
						<span>
							<Dropdown overlay={this.menu} trigger={["click"]}>
								<RiArrowDropDownLine size={20} color="black" cursor="pointer" />
							</Dropdown>
						</span>
					</div>
				);
		}
	};

	sendReplyMessage = () => {
		this.props.getReplyMessage(this.props.curr_chat.message, this.props.fromMe);
	};

	menu = (
		<Menu>
			<Menu.Item key="0" onClick={this.sendReplyMessage}>
				<div>Reply Message</div>
			</Menu.Item>
		</Menu>
	);

	triggerSearchFocus = () => {
		if (this.innerRef) {
			this.innerRef.current.scrollIntoView({ behavior: "smooth" });
			this.innerRef.current.classList.add("focus-animation");
		}
	};

	render() {
		const status = this.state.status !== "" ? this.state.status : this.props.curr_chat.status;
		const todayDate = dayjs().format("D-M-YYYY");
		var readByText = "";
		let sentByText = "";
		if (this.props.curr_chat.readBy) {
			readByText = `Read by: ${this.props.curr_chat.readBy}`;
		}
		if (this.props.sentByText !== "") {
			sentByText = this.props.sentByText;
		}
		return (
			<Row
				ref={this.innerRef}
				style={{ padding: "0.25em 1em", marginRight: "-200px !important", scrollMargin: "2em" }}>
				{this.props.fromMe && <Col flex="auto"></Col>}
				<Col
					style={{
						maxWidth: "80%",
						padding: "1em",
						borderRadius: "5px",
						background: this.props.fromMe ? "#bee9e8" : "#fefefe",
					}}>
					{this.getRequiredMessageType(this.props.curr_chat)}
					<Row justify="end" align="bottom" gutter={12}>
						<Col
							style={{
								color: "#393E46",
								fontWeight: "600",
								opacity: 0.5,
								margin: "0.25em 0.5em 0.25em 0",
							}}>
							{todayDate === this.props.curr_chat.messageDate ? "" : this.props.curr_chat.messageDate}{" "}
							{this.props.curr_chat.messageTime}
						</Col>
						{this.props.fromMe && <Col>{statusIcons[status]}</Col>}
					</Row>
					<Row>
						<Col
							style={{
								color: "#393E46",
								fontWeight: "600",
								opacity: 0.5,
								fontSize: "10px"
							}}>
							{!this.props.fromMe && readByText}
							{this.props.fromMe && sentByText}
						</Col>
					</Row>
				</Col>
			</Row>
		);
	}
}

export default forwardRef((props, ref) => <Message ref={ref} innerRef={ref} {...props} />);
