import React, { useState } from 'react';
import { Select, Input } from 'antd';
import { unparse } from 'papaparse';
import downloadCSV from '../utils/downloadCSV';
import downloadXLSX from '../utils/downloadXLSX';
import 'antd/es/date-picker/style/index';
import { DeliveredProcedureOutlined } from '@ant-design/icons';
import '../css/reportPage.css';

import ReportSearchEntry from './ReportSearchEntry';
import ReportAnalytics from './ReportAnalytics';
import QueryAnalytics from './QueryAnalytics';
import DatePickerModal from './DatePickerModal';
import ReportExportModal from './ReportExportModal';
import FilterBarCompo from "./FilterBarCompo.jsx";

import { getIndex } from "../Algolia.js";
import {
    resultsWindow,
  } from "../utils/contants";
  

const { Option } = Select;
const { Search } = Input;

export default function ReportPage({ adminId }) {
    const [analyticsFilterParam, setAnalyticsFilterParam] = useState(resultsWindow.FOREVER.value);
    const [queryFilterParam, setQueryFilterParam] = useState(resultsWindow.FOREVER.value);
    const [analyticsData, setAnalyticsData] = useState({});
    const [isAnalyticsModalVisible, setIsAnalyticsModalVisible] = useState(false);
    const [isQueryModalVisible, setIsQueryModalVisible] = useState(false);
    const [reportExportModalVisible, setReportExportModalVisible] = useState(false);
    const [analyticsStartDate, setAnalyticsStartDate] = useState(new Date());
    const [analyticsEndDate, setAnalyticsEndDate] = useState(new Date());
    const [queryStartDate, setQueryStartDate] = useState(new Date());
    const [queryEndDate, setQueryEndDate] = useState(new Date());
    const [searchResults, setSearchResults] = useState([]);
    const [queryData, setQueryData] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [selectedTags, setSelectedTags] = useState([]);

    const handleAnalyticsFilterChange = (newValue) => {
        setAnalyticsFilterParam(newValue);
        if(newValue === resultsWindow.SET_RANGE.value) setIsAnalyticsModalVisible(true);
    }

    const handleQueryFilterChange = (newValue) => {
        setQueryFilterParam(newValue);
        if(newValue === resultsWindow.SET_RANGE.value) setIsQueryModalVisible(true);
    }

    const handleDateRangeSelect = (startDate, endDate, client) => {
        if(client === 'analytics') {
            setAnalyticsStartDate(startDate);
            setAnalyticsEndDate(endDate);
        } else if(client === 'query') {
            setQueryStartDate(startDate);
            setQueryEndDate(endDate);
        }
    }

    const handleReportExport = () => {
        setReportExportModalVisible(true);
    }

    const handleQueryExport = () => {
        const fields = ['intent', 'frequency'];
        const data = queryData.map(([ intent, frequency ]) => [ intent.replace(/\s+/g, ''), frequency ])
        const csv = unparse({ fields, data });
        downloadCSV(csv, 'query-export.csv'); 
    }

    const handleSearchExport = () => {
        const data = [];
        searchResults.forEach(result => {
            data.push({Name: `${Object(result).name}`, Phone: `${Object(result).phone}`,
            Data: `${Object(result).data}`, TimeAndDate: `${Object(result).messageDateTime}`,})
        });
        // console.log(data)

        downloadXLSX(data, 'search-export');
    
    }

    const filterByTags = (tags) => {
        setSelectedTags(tags);
        searchAndFilter(searchQuery, tags);
    }

    const performSearch = (query) => {
        setSearchQuery(query);
        searchAndFilter(query, selectedTags);
    }
    
    const searchAndFilter = (query, tags) => {
        const results = [];
        if (query === "" && tags.length === 0) {
            setSearchResults(results);
        } else {
            const tagQuery = tags.map(tag => `tags:"${tag}"`).join(" OR ");
            const algoliaIndex_ = getIndex(localStorage.getItem('phoneNum'));
            algoliaIndex_
                .search(query, {
                    filters: tagQuery,
                    restrictSearchableAttributes: ["messages"],
                    attributesToRetrieve: ["name", "phone", "contactID", "messages", "messagesDateTime"],
                    hitsPerPage: 100,
                })
                .then(({ hits }) => {
                    for (const hit in hits) {
                        const name = hits[hit].name;
                        const contactId = hits[hit].contactID;
                        const phone = hits[hit].phone;
                        const messages = hits[hit].messages;
                        const messageDateTimes = hits[hit].messagesDateTime;
                        const highlightResults = hits[hit]._highlightResult;
                        const matchedMessages = highlightResults.messages;
                        if (contactId === undefined) continue;
                        let data = "", messageDateTime="";
                        for (let i = matchedMessages.length - 1; i >= 0; i--) {
                            if (matchedMessages[i].matchLevel === "full") {
                                data = messages[i];
                                messageDateTime = messageDateTimes !== undefined ? messageDateTimes[i] : "";
                                break;
                            }
                        }
                        
                        results.push({ name, phone, data, messageDateTime });
                    }
                    setSearchResults(results);
                });
        }
    }
    
    return (
        <div className="main-report-container">
            <div className="info-container">
                <div className="info-header">
                    <div>
                        <span className="info-header-text">Report</span>
                        <Select defaultValue={resultsWindow.FOREVER.value} onChange={handleAnalyticsFilterChange} >
                            <Option value={resultsWindow.LAST_YEAR.value}>{resultsWindow.LAST_YEAR.label}</Option>
                            <Option value={resultsWindow.LAST_MONTH.value}>{resultsWindow.LAST_MONTH.label}</Option>
                            <Option value={resultsWindow.LAST_WEEK.value}>{resultsWindow.LAST_WEEK.label}</Option>
                            <Option value={resultsWindow.TODAY.value}>{resultsWindow.TODAY.label}</Option>
                            <Option value={resultsWindow.FOREVER.value}>{resultsWindow.FOREVER.label}</Option>
                            <Option value={resultsWindow.SET_RANGE.value}>{resultsWindow.SET_RANGE.label}</Option>
                        </Select>
                    </div>
                    <div><DeliveredProcedureOutlined /><button className="export-btn" onClick={handleReportExport}>Export</button></div>
                </div>
                <ReportAnalytics
                    adminId={adminId}
                    adminNum={localStorage.getItem("phoneNum")}
                    filterParam={analyticsFilterParam}
                    startDate={analyticsStartDate}
                    endDate={analyticsEndDate}
                    setData={setAnalyticsData}
                />
            </div>
            <div className="info-container">
                <div className="info-header">
                    <div>
                        <span className="info-header-text">Most asked Queries Instances</span>
                        <Select defaultValue={resultsWindow.FOREVER.value} onChange={handleQueryFilterChange} >
                            <Option value={resultsWindow.LAST_YEAR.value}>{resultsWindow.LAST_YEAR.label}</Option>
                            <Option value={resultsWindow.LAST_MONTH.value}>{resultsWindow.LAST_MONTH.label}</Option>
                            <Option value={resultsWindow.LAST_WEEK.value}>{resultsWindow.LAST_WEEK.label}</Option>
                            <Option value={resultsWindow.TODAY.value}>{resultsWindow.TODAY.label}</Option>
                            <Option value={resultsWindow.FOREVER.value}>{resultsWindow.FOREVER.label}</Option>
                            <Option value={resultsWindow.SET_RANGE.value}>{resultsWindow.SET_RANGE.label}</Option>
                        </Select>
                    </div>
                    <div><DeliveredProcedureOutlined /><button onClick={handleQueryExport} className="export-btn">Export</button></div>
                </div>
                {/* <div className="query-stats-container">
                    {queryData.map(([ intent, frequency ]) => (
                        <div className="query-stats-item">
                            <span className="query-stats-item-title">{intent}</span>
                            <span className="query-stats-item-count">{frequency}</span>
                        </div>
                    ))}
                </div> */}
                <QueryAnalytics
                    adminId={adminId}
                    adminNum={localStorage.getItem('phoneNum')}
                    filterParam={queryFilterParam}
                    startDate={queryStartDate}
                    endDate={queryEndDate}
                    setData={setQueryData}
                />
            </div>
            <div className="info-container">
                <Search 
                    className="search-input" 
                    placeholder="Search here"
                    onSearch={performSearch}
					allowClear
                />
                <span className="filter-container">
                    <FilterBarCompo 
                        filterByTags={filterByTags}
                        adminNum={localStorage.getItem('phoneNum')}
                    />
                </span>
            </div>
            <div className="info-container">
                <span className="search-result-header">Total {searchResults.length} Results found</span>
                <span><DeliveredProcedureOutlined /><button onClick={handleSearchExport} className="export-btn">Export</button></span>
                <div className="search-result-container">
                    {searchResults.map(({ name, phone, data, messageDateTime }) => <ReportSearchEntry name={name} phone={phone} data={data} messageDateTime={messageDateTime}/>)}
                </div>
            </div>
            <DatePickerModal
                isModalVisible={isAnalyticsModalVisible}
                setIsModalVisible={setIsAnalyticsModalVisible}
                handleConfirm={handleDateRangeSelect}
                client='analytics'
            />
            <DatePickerModal
                isModalVisible={isQueryModalVisible}
                setIsModalVisible={setIsQueryModalVisible}
                handleConfirm={handleDateRangeSelect}
                client='query'
            />
            <ReportExportModal isModalVisible={reportExportModalVisible} setIsModalVisible={setReportExportModalVisible} data={analyticsData} />
        </div>
    )
}