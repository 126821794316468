import firebase from "firebase";
import "firebase/storage"

const config = {
  apiKey: "AIzaSyDM99YszprFO9aNQo5ZU-lqk_zmPenoCu8",
  authDomain: "chatbot-fa55a.firebaseapp.com",
  databaseURL: "https://chatbot-fa55a.firebaseio.com",
  projectId: "chatbot-fa55a",
  storageBucket: "chatbot-fa55a.appspot.com",
  messagingSenderId: "674507309893",
  appId: "1:674507309893:web:c96f91a7122c12be11f42c",
  measurementId: "G-2HD4WDY8C6"
};
// Initialize Firebase
firebase.initializeApp(config);

export default firebase;
