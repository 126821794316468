const hashCode = (str) => {
	// java String#hashCode
	let hash = 0;
	if (str === undefined) return hash;
	for (let i = 0; i < str.length; i++) {
		hash = str.charCodeAt(i) + ((hash << 5) - hash);
	}
	return hash;
};

const intToRGB = (i) => {
	const c = (i & 0x00ffffff).toString(16).toUpperCase();
	return "#" + c.padStart(6, "0");
};
export { hashCode, intToRGB };
