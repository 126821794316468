import { useState, useRef } from "react";
import { Dropdown, Menu, Typography, Button, Input, Col, Row } from "antd";
import { BiMessageAltDetail, BiMessageAdd, BiTrash } from "react-icons/bi";

const QuickReplies = ({ isAutoOn, templateMessages, onChoose, addTemplateMessage, deleteTemplateMessage }) => {
	const btnRef = useRef(null);
	const [isVisible, setIsVisible] = useState(false);
	const [newTemplateMessage, setNewTemplateMessage] = useState("");

	const toggleVisible = () => setIsVisible((vis) => !vis);

	const handleAddNewReply = () => {
		if (newTemplateMessage !== "") {
			addTemplateMessage(newTemplateMessage);
			setNewTemplateMessage("");
		}
	};
	return (
		<Dropdown
			visible={isVisible}
			placement="topLeft"
			disabled={isAutoOn}
			onVisibleChange={toggleVisible}
			trigger={["hover"]}
			overlayStyle={{ width: "400px" }}
			getPopupContainer={(node) => node}
			overlay={
				<Menu>
					<Menu.ItemGroup title="Quick Replies">
						<Menu.Divider />
						{templateMessages.length > 0 ? (
							templateMessages.map((message, i) => {
								return (
									<Menu.Item
										key={i}
										style={{
											borderBottom: "1px solid #dddddd",
											padding: "1em",
										}}>
										<Row align="middle">
											<Col span={23} onClick={() => onChoose(message)}>
												<Typography.Paragraph
													style={{ marginBottom: "0", whiteSpace: "break-spaces" }}>
													{message}
												</Typography.Paragraph>
											</Col>
											<Col span={1}>
												<Button
													size="small"
													shape="circle"
													onClick={() => deleteTemplateMessage(message)}
													icon={<BiTrash />}
												/>
											</Col>
										</Row>
									</Menu.Item>
								);
							})
						) : (
							<Menu.Item>
								<i>No Template Messages found.</i>
							</Menu.Item>
						)}
					</Menu.ItemGroup>
					<Menu.Divider />
					<Menu.Item>
						<Input
							placeholder="Add a quick reply"
							value={newTemplateMessage}
							onChange={(e) => setNewTemplateMessage(e.target.value)}
							onKeyPress={(e)=>{
								if(e.key === "Space"){
									e.preventDefault();
								}
							}}
							onPressEnter={handleAddNewReply}
							suffix={
								<Button onClick={handleAddNewReply}>
									<BiMessageAdd size={25} />
								</Button>
							}
						/>
					</Menu.Item>
				</Menu>
			}>
			<Button ref={btnRef} className="icon-button" disabled={isAutoOn}>
				<BiMessageAltDetail size={30} />
			</Button>
		</Dropdown>
	);
};

export default QuickReplies;
