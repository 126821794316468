import algoliasearch from "algoliasearch";


const adminNum = localStorage.getItem("phoneNum");

const algoliaClient = algoliasearch(
    "ZDT0KPRQ6O",
    "da2f5bb0f111cc4ceea5b26969908917"
);

// const getAlgoliaIndex = () => {
//     return algoliaClient.initIndex(adminNum);
// }

export const algoliaIndex = algoliaClient.initIndex(adminNum);

export const getIndex = (phoneNum) => {
    const index = algoliaClient.initIndex(phoneNum);
    return index;
}
