import React from "react";
import ReactDOM from "react-dom";
import "./css/index.css";
import App from "./App";

import { initAmplitude } from "./utils/amplitudeFunctions";

initAmplitude();

ReactDOM.render(<App />, document.getElementById("root"));
