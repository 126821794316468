import React, { useContext, useEffect, useState } from "react";
import {
  MoreOutlined,
  DownloadOutlined,
  TagsOutlined,
  UserDeleteOutlined,
  UserAddOutlined
} from "@ant-design/icons";
import firebase from "../Firebase.js";
import { Dropdown, Menu, Button } from "antd";
import TagManagerModal from "./TagManager";
import { myContext } from "./myContext.js";
import {
  hospital_tags,
  } from "../utils/contants";

function setTags(adminNum, setTags) {
  firebase
				.database()
				.ref(`${hospital_tags}/${adminNum}`)
				.on("value", (snapshot) => {
					if (snapshot.exists()) {
						setTags(snapshot.val());
					}
				});
}

export default function SimplePopover({
  adminID,
  contactID,
  handleChatExport,
  handleBlock,
  chatId,
  blocked
}) {
  const [isTagDialogOpen, setIsTagDialogOpen] = useState(false);
  const [uniqueTags, setUniqueTags] = useState([]);
	const { adminId } = useContext(myContext);

	useEffect(() => {
		setTags(localStorage.getItem('phoneNum'), setUniqueTags);
	}, [adminId]);

  const blockIcon = blocked ? <UserAddOutlined /> : <UserDeleteOutlined />;
  const blockText = blocked ? "Unblock" : "Block";

  const menu = (
    <Menu>
      <Menu.Item
        key="1"
        onClick={() => setIsTagDialogOpen(true)}
        icon={<TagsOutlined />}
      >
        Manage Tags
      </Menu.Item>
      <Menu.Item key="2" icon={<DownloadOutlined />} onClick={handleChatExport}>
        Export Chat
      </Menu.Item>
      <Menu.Item key="3" icon={blockIcon} onClick={handleBlock}>
        {blockText}
      </Menu.Item>
    </Menu>
  );
  return (
    <>
      {isTagDialogOpen && (
        <TagManagerModal
          isOpen={isTagDialogOpen}
          toggleModal={() => setIsTagDialogOpen(false)}
          contactID={contactID}
          adminID={adminID}
          chatId={chatId}
		      UniqueTags={ uniqueTags }
        />
      )}

      <Dropdown overlay={menu} arrow>
        <Button
          size="large"
          className="icon-button"
          icon={<MoreOutlined />}
        ></Button>
      </Dropdown>
    </>
  );
}
