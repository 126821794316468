import React, { useState, useEffect } from "react";
import { Button, Tag, Popover, Divider, Row, Col } from "antd";
import { FilterOutlined, ClearOutlined, CheckOutlined } from "@ant-design/icons";

import firebase from "../Firebase.js";
import {
	hospital_tags,
  } from "../utils/contants"
export default function FilterBarCompo({ otherFilters, filterByTags, adminNum }) {
	const [isMenuVisible, setIsMenuVisible] = React.useState(false);
	const [selectedTags, setSelectedTags] = React.useState([]);
	const [tags, setTags] = useState([]);

	useEffect(() => {
		const getUniqueTags = (adminNum, setTags) => {
			firebase
				.database()
				.ref(`${hospital_tags}/${adminNum}`)
				.on("value", (snapshot) => {
					if (snapshot.exists()) {
						setTags(snapshot.val());
					}
				});
		}
		getUniqueTags(adminNum, setTags);
	}, [adminNum])

	function handleApplyFilter() {
		filterByTags(selectedTags);
	}
	function handleClearFilter() {
		setSelectedTags([]);
		filterByTags([]);
	}
	const handleTagSelection = (tag, checked) => {
		const nextSelectedTags = checked ? [...selectedTags, tag] : selectedTags.filter((t) => t !== tag);
		setSelectedTags(nextSelectedTags);
	};

	const menu = (
		<>
			{otherFilters}
			<Divider orientation="left">
				<span style={{ color: "#555555", fontSize: "smaller" }}>Filter by Tags</span>
			</Divider>
			{tags.map((tag) => (
				<Tag.CheckableTag
					key={tag}
					color="blue"
					checked={selectedTags.includes(tag)}
					onChange={(checked) => handleTagSelection(tag, checked)}>
					{tag}
				</Tag.CheckableTag>
			))}
			<Row gutter={24} style={{ marginTop: "1em" }}>
				<Col>
					<Button icon={<ClearOutlined />} disabled={selectedTags <= 0} onClick={handleClearFilter}>
						Clear
					</Button>
				</Col>
				<Col>
					<Button icon={<CheckOutlined />} type="primary" onClick={handleApplyFilter}>
						Apply Tag Filters
					</Button>
				</Col>
			</Row>
		</>
	);
	return (
		<Popover
			placement="rightBottom"
			overlayStyle={{ maxWidth: "320px" }}
			content={menu}
			visible={isMenuVisible}
			onVisibleChange={setIsMenuVisible}>
			<Button icon={<FilterOutlined />}>Filter</Button>
		</Popover>
	);
}
