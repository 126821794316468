import React from "react";
import { Redirect } from "react-router-dom";
import firebase from "../Firebase";
import {
    adminUID ,
    token,
    pwa_token_list,
  } from "../utils/contants";


const Logout = (props) => {
    const adminId = props?.location?.query?.adminId;
    
    if (token && adminId) {
        firebase.database().ref(`${pwa_token_list}/${adminId}/${adminUID}/${token}`).set(null);
    }
    localStorage.clear();
    return <Redirect to="/login" />;
};
export default Logout;
