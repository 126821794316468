import React, { Component } from "react";
import Sidebar from "./Sidebar";
import OpenConversation from "./OpenConversation";
import firebase from "../Firebase.js";
import { myContext } from "./myContext";
import { Spin } from "antd";
import {
	UserCredentials,
	Users,
	userPhone,
  } from "../utils/contants";
var value;

async function getAllAdminDetails(phoneNumber) {
	const ref = firebase.database().ref(`${UserCredentials}`);
	const snapshot = await ref.orderByChild("phoneNumber").equalTo(phoneNumber).once("value");
	const adminList = snapshot.exists() ? snapshot.val() : [];
	return adminList;
}

class Dashboard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			adminId: "",
			loading: true,
			selectedContact: {},
			selectedChatId: "no-select",
			messageIndex: -1,
			adminList: [],
			adminMap: {},
			adminPhoneNum: localStorage.getItem("phoneNum"),
			adminName: localStorage.getItem("adminName"),
		};
	}

	changeContactAndChatId = (contact, chatId, messageIndex = -1) => {
		this.setState({
			selectedContact: contact,
			selectedChatId: chatId,
			messageIndex: messageIndex,
		});
	};

	componentDidMount() {
		getAllAdminDetails(this.state.adminPhoneNum).then((result) => {
			const adminList = Object.values(result);
			let adminMap = {};
			adminList.forEach(admin => {
				const id = admin.uid;
				const name = admin.name;
				const department = admin.department;
				adminMap[id] = {
					name,
					department
				};
			});
			this.setState({ 
				adminList,
				adminMap
			 });
		});
		firebase
			.database()
			.ref(`${Users}`)
			.orderByChild(`${userPhone}`)
			.equalTo(this.state.adminPhoneNum)
			.once("value", (snapshot) => {
				snapshot.forEach((childSnapshot) => {
					value = childSnapshot.val();
					this.props.setAdminId(value.id);
				});
				this.setState({
					adminId: value.id,
					loading: false,
				});
			});
		if (this.props.selectedChatId !== "") {
			this.setState({
				selectedContact: this.props.selectedContact,
				selectedChatId: this.props.selectedChatId
			})
		}
	}

	render() {
		if (this.state.loading) {
			return (
				<div className="buffer_loader">
					<Spin size="large" />
				</div>
			);
		}
		return (
			<div className="main_container">
				<myContext.Provider value={this.state}>
					<Sidebar changeContactAndChatId={this.changeContactAndChatId} adminList={this.state.adminList} />
					<div className="openconv">
						<OpenConversation
							contact={this.state.selectedContact}
							chatId={this.state.selectedChatId}
							messageIndex={this.state.messageIndex}
						/>
					</div>
				</myContext.Provider>
			</div>
		);
	}
}

export default Dashboard;
