import React, { Component } from "react";
import { Modal } from 'antd';


export default class ClickImageModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            visible: false,
        }
    }

 
    visibleModal = () => {
        this.setState({ visible: true });
      };

      handleOk = () => {
        this.setState({ visible: false });
      };
    
      handleCancel = () => {
        this.setState({ visible: false });
      };

    render() {
        return (
            <div>
                 <Modal
          visible={this.state.visible}
          title="Preview"
          width="50%"
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={null}
        >
          <img src = {this.props.imageSRC} alt="sample" id="modal_image"/>
        </Modal>
            </div>
        )
    }
}
