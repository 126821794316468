import React, { useEffect, useState } from "react";
import { Input, Modal, Tag } from "antd";

import firebase from "../Firebase.js";
import { intToRGB, hashCode } from "../utils/colorUtils";
import { algoliaIndex } from "../Algolia.js";
import {
    adminNum,
    v2_userChats,
    hospital_tags,
    
  } from "../utils/contants";
  import {
    sendTagAnalytics,
  } from "../utils/functions";

export default function TagManagerModal({
    isOpen,
    contactID,
    adminID,
    toggleModal,
    chatId,
    UniqueTags,
}) {
    const [tags, setTags] = useState([]);
    const [newTag, setNewTag] = useState("");
    const dbRef = firebase
        .database()
        .ref(`${v2_userChats}/${adminID}/${contactID}/tags`);
    const [selectedTags, setSelectedTags] = React.useState([]);

    useEffect(() => {
        const _dbRef = firebase
            .database()
            .ref(`${v2_userChats}/${adminID}/${contactID}/tags`);
        _dbRef.on("value", (snapshot) => {
            if (snapshot.exists()) {
                const tagStr = snapshot.val();
                const uniqueTags = tagStr
                    .split("~")
                    .filter((tag) => tag !== "");
                setTags(uniqueTags);
            }
        });
        return () => {
            _dbRef.off();
        };
    }, [adminID, contactID]);

    const updateTags = (newTags, tagToBeRemoved) => {
        setSelectedTags(newTags);
        algoliaIndex
            .partialUpdateObject({
                objectID: chatId,
                tags: newTags,
            })
            .then(console.log)
            .catch(console.log);
        const updatedTagString = newTags.join("~");
        const tagsAdded = newTags.filter(tag => !UniqueTags.includes(tag));
        if (tagsAdded.length > 0) {
            const updatedUniqueTags = UniqueTags.concat(tagsAdded);
            firebase.database().ref(`${hospital_tags}/${adminNum}`).set(updatedUniqueTags);
        }
        dbRef.set(updatedTagString);
        sendTagAnalytics(tagToBeRemoved, "remove");
    };
    const removeTag = (tagToBeRemoved) => {
        const newTags = tags.filter((tag) => tag !== tagToBeRemoved);
        updateTags(newTags, tagToBeRemoved);
    };
    const addTag = (tagToBeAdded) => {
		const lowerCaseTag = tagToBeAdded.toLowerCase();
        if (!tags.includes(tagToBeAdded) && lowerCaseTag !== 'open' && lowerCaseTag !== 'closed' && lowerCaseTag !== 'ongoing') {
            const newTags = [...tags, tagToBeAdded];
            updateTags(newTags, tagToBeAdded);
        }
    };

    const addListTag = (newTags) => {
        algoliaIndex
            .partialUpdateObject({
                objectID: chatId,
                tags: newTags,
            })
            .then(console.log)
            .catch(console.log);
        const updatedTagList = tags.concat(newTags.filter(tag => !tags.includes(tag)));
        const updatedTagString = updatedTagList.join("~");
        dbRef.set(updatedTagString);
    };

    const handleTagSelection = (tag, checked) => {
        const nextSelectedTags = checked
            ? [...selectedTags, tag]
            : selectedTags.filter((t) => t !== tag);
        setSelectedTags(nextSelectedTags);
        addListTag(nextSelectedTags);
    };

    return (
        <Modal
            cancelText="Close"
            okText="Add"
            visible={isOpen}
            title={<span style={{ fontWeight: 600 }}>Manage Tags </span>}
            closable={false}
            onCancel={toggleModal}
            okButtonProps={{ disabled: newTag.trim() === "" }}
            onOk={() => {
                addTag(newTag.trim());
                setNewTag("");
            }}
        >
            {UniqueTags.filter((tag) => 
                tag !== "open" && tag !== "closed" && tag !== "ongoing"
            ).map((tag) => (
                <Tag.CheckableTag
                    key={tag}
                    color="blue"
                    checked={selectedTags.includes(tag)}
                    onChange={(checked) => {
                        handleTagSelection(tag, checked);
                    }}
                >
                    {tag}
                </Tag.CheckableTag>
            ))}
            <div style={{ margin: "1em 0" }}>
                {tags.map((tag) => {
                    const hexColor = intToRGB(hashCode(tag));
                    const isClosable =
                        tag !== "open" && tag !== "closed" && tag !== "ongoing";
                    return (
                        <Tag
                            closable={isClosable}
                            key={tag}
                            onClose={() => removeTag(tag)}
                            style={{
                                backgroundColor: hexColor,
                                color: "#f5f5f5",
                                margin: "0.25em",
                                fontSize: "larger",
                                padding: "0.5em",
                            }}
                        >
                            {tag + "     "}
                        </Tag>
                    );
                })}
            </div>
            <Input
                value={newTag}
                placeholder="Add New Tag"
                style={{ borderRadius: "30pt", padding: "1em" }}
                onChange={(e) => setNewTag(e.target.value)}
            />
        </Modal>
    );
}
