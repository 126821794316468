import React, { useState, useEffect } from "react";

import { Popover, Button } from "antd";
import { AiOutlineMessage } from "react-icons/ai";
import TemplateMessageModel from "./TemplateMessageModel";
import {  Empty, Spin,  } from "antd";
import {
	db ,
	adminNum,
	FETCHING_TEMPLATES,
	templateMessages,
  } from "../utils/contants";

export default function SingleTemplateMessage({ isAutoOn,props , contact, ...passed }) {
	const [modalType, setModalType] = useState("none");
	const [Data, setData] = useState({});
    const [allTemplates, setTemplates] = useState([]);
	const [loading, setLoading] = useState(FETCHING_TEMPLATES);

	useEffect(() => {
		db.ref(`${templateMessages}/${adminNum}`).once("value", (snapshot) => {
			if (snapshot.exists()) {
				const templateList = snapshot.val().filter((entry) => entry);
				setTemplates(templateList);
			}
			
			setLoading("");
		});
	}, []);
	
	const content = (
		<div>
			<div>
			{allTemplates.length > 0 ? (
						allTemplates.map((data) => (
							
							<div>
							<Button
							size="large"
							type="dashed"
							block
						onClick={() => {setModalType(data.name);
							setData(data);
						} 
						 }
						
					style={{ marginBottom: "1em" }}>
					
					 {data.name}
					 
				</Button>
				
						</div>	
						
						))
					) : loading === FETCHING_TEMPLATES ? (
						<Spin size="small" />
					) : (
						<Empty description="No Templates in your account" />
					)
					}
			
			</div>
			
		</div>
	);
	return (
		<>
		{modalType !== "none" && (
				<TemplateMessageModel
					isVisible={modalType !== "none"}

					type={ modalType }
					{...passed}
					hideModal={() => setModalType("none")}
					Data={Data}
					
				/>
			)}
    
			<Popover placement="topLeft" content= {content} title="Single Template Messages" trigger="click">
				<Button disabled={isAutoOn} className="icon-button">
					<AiOutlineMessage size={30} />

				</Button>
			</Popover>
		</>
	);
}
