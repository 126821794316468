import React from "react";
import { Button, Card, Typography } from "antd";
import { SelectOutlined, DownloadOutlined } from "@ant-design/icons";

export default function TemplateCardsLayout({ data, setSelectedTemplateData, isSelected, downloadExampleCSV }) {
	const newStr = data.params.replace(/\$/g, ", ");
	return (
		<Card
			bordered
			className={`template-card ${isSelected ? "highlighted" : ""}`}
			actions={[
				<Button onClick={downloadExampleCSV} icon={<DownloadOutlined />}>
					Download Example CSV
				</Button>,
				<Button
					type="primary"
					onClick={() => {
						if (isSelected) {
							setSelectedTemplateData({});
						} else {
							setSelectedTemplateData(data);
						}
					}}
					icon={<SelectOutlined />}>
					{isSelected ? "Deselect" : "Select"} this Template
				</Button>,
			]}>
			<span>
				<span style={{ fontWeight: 600 }}>Template:</span>
				<Typography>{data.template}</Typography>
			</span>
			<br />
			<span>
				<span style={{ fontWeight: 600 }}>Columns:</span>
				<Typography>{newStr}phone</Typography>
			</span>
			<br />
			<span>
				<span style={{ fontWeight: 600 }}>Example:</span>
				<Typography>{data.example}</Typography>
			</span>
		</Card>
	);
}
