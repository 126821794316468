import React, { useState } from "react";
import { Button, Form, Input, Row, Col, Card } from "antd";
import { LockOutlined } from "@ant-design/icons";
import side_image from "../Images/left_side_image.jpg";
import logo from "../Images/logo.png";
import { useHistory } from "react-router-dom";
import {
	resetPassword,
  } from "../utils/functions";

function ResetPassword(props) {
	const [loading, setLoading] = useState(false);
    const history = useHistory();
	 
	const handleSubmit = async (values) => {
		setLoading(true);
		const isResetSuccessful = await resetPassword( values.password );
		console.log(isResetSuccessful);
		setLoading(false);
		if (isResetSuccessful) {
			// !history push doesn't load render the chatlist for some reason
			history.push('/login');
		}
	};
	return (
		<div className="main_form_container">
			<Card className="main_container_card">
				<div className="card_contents_form">
					<div className="side_image">
						<img id="side_image" src={side_image} alt="Test"></img>
					</div>
					<Form
						size="large"
						layout="vertical"
						name="Login"
						onFinish={handleSubmit}
						className="form-container">
						<div className="logo">
							<img id="logo" src={logo} alt="Logo"></img>
						</div>
						<div id="title_login">Reset Your Password </div>
						<br />
						<Form.Item
							name="password"
							rules={[
								{
									required: true,
									message: "Please input your password!",
								},
								{
									type: "string",
									min: 8,
									message: "Password must be at least 8 characters long"
								},
							]}>
							<Input.Password placeholder="Password" addonBefore={<LockOutlined />} />
						</Form.Item>
                        <br/>
                        <Form.Item
						name="confirm-password"
						rules={[
							{
								required: true,
								message: "Please confirm your password!",
							},
							({ getFieldValue }) => ({
								validator(rule, value) {
								  if (!value || getFieldValue('password') === value) {
									return Promise.resolve();
								  }
								  return Promise.reject('The two passwords that you entered do not match!');
								},
							  }),
						]}>
						<Input.Password placeholder="Confirm Password" addonBefore={<LockOutlined />} />
					</Form.Item>

						<Row justify="end" align="middle" style={{ paddingTop: "2em" }}>
							<Col span={12} style={{ textAlign: "right" }}>
								<Form.Item style={{ marginBottom: "0px" }}>
									<Button
										style={{ width: "100%" }}
										type="primary"
										size="large"
										htmlType="submit"
										loading={loading}>
										Submit
									</Button>
								</Form.Item>

							</Col>
						</Row>
					</Form>
				</div>
			</Card>
		</div>
	);
}

export default ResetPassword;
