import firebase from "../Firebase";
import { RiCheckDoubleFill, RiCheckFill, RiTimeLine } from "react-icons/ri";

export const db = firebase.database();
export const adminUID = localStorage.getItem("adminUID");
export const adminName = localStorage.getItem("adminName");
export const adminNum = localStorage.getItem("phoneNum");
export const adminDepartment = localStorage.getItem("adminDepartment");
export const carrier = localStorage.getItem("carrier") || "gupshup";
export const token = localStorage.getItem("notificationToken");
export const hospitalName = localStorage.getItem("hospitalName");
export const FETCHING_TEMPLATES = "FETCHING_TEMPLATES";
export const SENDING_MESSAGES = "SENDING_MESSAGES";
export const header_ = {
    headers: {
      "app-name": "prescribe-chatbot-admin",
    },
  };
export  const resultsWindow = {
    LAST_YEAR: { value: 'last_year', label: 'Last Year' },
    LAST_MONTH: { value: 'last_month', label: 'Last Month' },
    LAST_WEEK: { value: 'last_week', label: 'Last Week' },
    TODAY: { value: 'today', label: 'Today' },
    FOREVER: { value: 'forever', label: 'Forever' },
    SET_RANGE: { value: 'set_range', label: 'Set Range' }
}

export const sortCriteria = {
    DATE: "date",
    ASC_RATING: "asc_rating",
    DES_RATING: "des_rating",
    NAME: "name"
};

export const options = {
	plugins: {
		legend: {
		display: false
		},
		tooltip: {
		mode: 'index',
		intersect: false
		}
	 },
	hover: {
	mode: 'nearest',
	intersect: false
	 },
	scales: {
		yAxes: [
			{
				stacked: true,
				ticks: {
					beginAtZero: true,
				},
			},
		],
	},
};

export const statusIcons = {
	enqueued: <RiTimeLine color="gray" size={18} />,
	sent: <RiCheckFill color="gray" size={18} />,
	delivered: <RiCheckDoubleFill color="gray" size={18} />,
	read: <RiCheckDoubleFill color="teal" size={18} />,
	// read: <RiCheckDoubleFill color="teal" style={{border: "2px solid teal", borderRadius: "100%"}} size={18} />,
};

export const exportOptions = {
    EVERYTHING: { label: 'Everything', value: 'everything', index: -1 },
    ADMIN: { label: 'Admin Messages', value: 'admin_messages', index: 0 },
    AUTO: { label: 'Auto Reply', value: 'auto_reply', index: 1 },
    RECEIVED: { label: 'Received Messages', value: 'received_messages', index: 2 },
    SENT: { label: 'Sent Messages', value: 'sent_messages', index: 3 },
    SESSION: { label: 'Session Messages', value: 'session_messages', index: 4 },
    TEMPLATE: { label: 'Template Messages', value: 'template_messages', index: 5 },
	UNIQUE: { label: 'Unique Client', value: 'unique_client', index: 6 },
}
export const exportOptionsList = Object.values(exportOptions).filter(option => option.value !== exportOptions.EVERYTHING.value);

export const propValues = {
	image: {
		dirPath: "assets",
		fileTypes: "JPG and PNG",
		accept: "image/jpeg, image/png",
	},
	video: {
		dirPath: "Videos",
		fileTypes: "MP4 and 3GPP",
		accept: "video/mp4, video/3gpp",
	},
	document: {
		dirPath: "Documents",
		fileTypes: "PDF",
		accept: "application/pdf",
	},
};

export const UserCredentials ='UserCredentials';
export const Users ='Users';
export const v2_userChats ='v2_userChats';
export const v2_agentWiseChats ='v2_agentWiseChats';
export const v2_assignedChats ='v2_assignedChats';
export const  chatMessages = 'chatMessages';
export const closed ='closed';
export const open ='open';
export const ongoing ='ongoing';
export const child_added = 'child_added';
export const chatAnalytics = 'chatAnalytics';
export const subscriptionData = 'subscriptionData';
export const Chats = 'Chats';
export const userPhone = 'userPhone';
export const feedback_templates = 'feedback_templates';
export const Feedbacks = 'feedbacks';
export const hospital_tags = 'hospital_tags';
export const pwa_token_list = 'pwa_token_list';
export const intent_report = 'intent_report';
export const templateMessages = 'templateMessages';
export const feedback_statistics = 'feedback_statistics';