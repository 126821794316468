import React, { useContext, useEffect } from "react";
import { Tabs } from "antd";

import InfiniteList from "./InfiniteList.jsx";

import firebase from "../Firebase.js";
import "../css/sidebar.css";
import { myContext } from "./myContext.js";
import {
	changeFavicon,
	changeTitle,
  } from "../utils/functions";
  import {
    v2_userChats,
	
  } from "../utils/contants";


const { TabPane } = Tabs;

function setTagsAndUnreadCount(adminID) {
	firebase
		.database()
		.ref(`${v2_userChats}/${adminID}`)
		.on("value", (snapshot) => {
			if (snapshot.exists()) {
				const contacts = Object.values(snapshot.val());
				// const tagStrings = contacts.map(({ tags }) => tags).filter((str) => str !== "");
				// const uniqueTags = Array.from(new Set(tagStrings.join("~").split("~"))).filter((str) => str !== "");
				// setTags(uniqueTags);

				const numUnreadContacts = contacts.filter(({ unread }) => unread).length;
				changeFavicon(numUnreadContacts);
				changeTitle(numUnreadContacts);
			}
		});
}

export default function Sidebar(props) {
	// const [uniqueTags, setUniqueTags] = useState([]);
	const { adminId } = useContext(myContext);

	useEffect(() => {
		setTagsAndUnreadCount(adminId);
	}, [adminId, props.setTags]);

	const commonProps = {
		changeDashboardState: props.changeContactAndChatId,
	};
	return (
		<Tabs size="large">
			<TabPane forceRender tab="All" key="1">
				<InfiniteList {...commonProps} menuId="all" dbPath="v2_userChats/" />
			</TabPane>
			<TabPane forceRender tab="Mine" key="2">
				<InfiniteList {...commonProps} menuId="agentWise" dbPath="v2_agentWiseChats/" />
			</TabPane>
			{/* <TabPane forceRender tab="Assigned" key="3">
				<InfiniteList {...commonProps} menuId="assigned" dbPath="v2_assignedChats/" />
			</TabPane> */}
		</Tabs>
	);
}
