import { Modal, Input, Typography } from "antd";
import axios from "axios";
import React, { useState } from "react";
import reactStringReplace from "react-string-replace";
import {
  header_,
} from "../utils/contants";

export default function TemplateMessageModel(props) {
  const [isSending, setIsSending] = useState(false);
  const [templateData, setTemplateData] = useState({});

  const handleOk = (e) => {
    setIsSending(true);
    
    const params = [];
    props.Data.params.split('~').forEach((param) => {
      if (templateData[param]) {
        params.push(templateData[param]);
      }
    });

    const request = {
      template_id: props.Data.template_id,
      message: props.Data.template.replace(/{{(.*?)}}/g, (match) => templateData[match.substr(2, match.length - 4)] ),
      sender: props.adminNum,
      receiver: props.userPhone,
      name: props.Data.name,
      params
    };

    axios
      .post(
        "https://prescribe-message-backend.herokuapp.com/send-template-message",
        request,
        header_
      )
      .finally(() => {
        props.hideModal();
        setIsSending(false);
      });
  };
  
  return (
    <Modal
      title={props.type}
      visible={props.isVisible}
      onOk={handleOk}
      okButtonProps={{
        disabled: Input.value === "",
        loading: isSending,
      }}
      onCancel={props.hideModal}
      okText="Send"
    >
      <br />
      <row>
        <column>
          <Typography>
            {reactStringReplace(
			reactStringReplace(props.Data.template, "\\n", () => <br/>),
			/{{(.*?)}}/g,
              (match) =>{
				
                return match !== "\\n" ? (
                  <Input
                    name={match}
                    placeholder={match.replaceAll("_", " ").replace(/\b\w/g, (c) => c.toUpperCase())}
                    value={templateData[match]}
                    onChange={(e) =>
                      setTemplateData({
                        ...templateData,
                        [match]: e.target.value,
                      })
                    }
                  />
                ) : (
                  <br />
                )}
            )}
          </Typography>
        </column>
      </row>
      <br />
    </Modal>
  );
}
