import React, { useState } from "react";
import SideComponent from "./SidebarComponent";
import { Link, Route, BrowserRouter as Router, Switch } from "react-router-dom";
import logo from "../Images/logo.png";
import Dashboard from "./Dashboard";
import DataTable from "./AllContacts";
import TemplatePage from "./TemplatePage";
import ReportPage from "./ReportPage";
import FeedbackPage from "./FeedbackPage";
import { Layout } from "antd";
import {
	adminName,
	adminDepartment,
	hospitalName,
  } from "../utils/contants";

export default function MainDashboard() {
	const [adminId, setAdminId] = useState("");
	const [selectedContact, setSelectedContact] = useState({});
	const [selectedChatId, setSelectedChatId] = useState("");
	const [selectedPage, setSelectedPage] = useState("conversations");

	var displayName = adminName
	if (adminDepartment) {
		displayName += " - " + adminDepartment;
	}

	return (
		<div className="main_dashboard" style={{ height: "100vh" }}>
			<div className="main_top_bar">
				<div className="first_half">
					<img id="logo_dashboard" src={logo} alt="Logo"></img>
					{hospitalName}
				</div>
				<div className="second_half">
					<div className="admin_name">{displayName}</div>
					<Link to={{pathname: "/logout", query: { adminId }}} className="logout_menulink">
						<u>Logout</u>
					</Link>
				</div>
			</div>
			<Layout>
				<Router>
					<SideComponent adminId={adminId} selectedPage={selectedPage}/>
					<Layout.Content style={{ height: "calc(100vh - 64px)" }}>
						<Switch>
							<Route exact path="/dashboard/conversations">
								<Dashboard setAdminId={setAdminId} selectedContact={selectedContact} selectedChatId={selectedChatId}/>
							</Route>
							<Route exact path="/dashboard/contacts">
								<DataTable adminId={adminId} />
							</Route>
							{/* <Route exact path="/dashboard/analytics">
								<AnalyticsPage adminId={adminId} adminNum={localStorage.getItem("phoneNum")} />
							</Route> */}
							<Route exact path="/dashboard/reports">
								<ReportPage adminId={adminId} />
							</Route>
							<Route exact path="/dashboard/templates">
								<TemplatePage />
							</Route>
							<Route exact path="/dashboard/feedback">
								<FeedbackPage setSelectedContact={setSelectedContact} setSelectedChatId={setSelectedChatId} setSelectedPage={setSelectedPage}/>
							</Route>
						</Switch>
					</Layout.Content>
				</Router>
			</Layout>
			{/* </div> */}
		</div>
	);
}
