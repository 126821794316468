import React from "react";
import dayjs from "dayjs";
import { Card, Badge, Row, Typography, Col, Space, Tooltip, Avatar } from "antd";
import { ExclamationCircleOutlined, NotificationOutlined } from "@ant-design/icons";
import { intToRGB, hashCode } from "../utils/colorUtils";
import {
	AllTags,
  } from "../utils/functions";

function ChatWindowCard({ assistanceRequired, chatInfo, count, isActive, chatID, attendedBy, tags, onCloseTags }) {
	const currentDate = dayjs().format("D-M-YYYY");

	return (
		<Card
			type="inner"
			title={
				<Row align="middle" justify="space-between">
					<Space>
						<Avatar style={{ backgroundColor: intToRGB(hashCode(chatInfo.contactName)) }}>
							{chatInfo.contactName[0]}
						</Avatar>
						<strong style={{ color: "#393E46" }}>{chatInfo.contactName}</strong>
					</Space>
				</Row>
			}
			extra={
				<Row align="middle">
					{assistanceRequired && (
						<Tooltip placement="bottom" title="Assistance Required">
							<ExclamationCircleOutlined
								style={{ color: "#1890FF", fontSize: "1.2em", marginRight: "0.5em" }}
							/>
						</Tooltip>
					)}
					{tags.includes("Important") && <NotificationOutlined style={{ marginRight: "0.5em" }} />}
					<Badge count={count} />
				</Row>
			}
			hoverable
			style={{ margin: "0.5em" }}
			className={isActive && "active"}>
			{tags !== "" && <AllTags tags={tags} onCloseTags={onCloseTags} />}

			<Row style={{ marginTop: "1em" }}>
				<Col span={18}>
					<Typography.Paragraph ellipsis={{ rows: 2 }}>{chatInfo.lastMessage}</Typography.Paragraph>
				</Col>
				<Col span={6} style={{ textAlign: "right" }}>
					{currentDate === chatInfo.lastMessageDate ? chatInfo.lastMessageTime : chatInfo.lastMessageDate}
				</Col>
			</Row>

			{attendedBy !== "none" && (
				<Card.Meta
					title={
						<Typography.Text type="secondary" style={{fontSize: "13px"}}>
							Attended By: <strong>{attendedBy}</strong>
						</Typography.Text>
					}
				/>
			)}
		</Card>
	);
}

export default ChatWindowCard;
