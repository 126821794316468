import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router";
import { Row, Col, Upload, Button, Input, Select, Table, Modal } from "antd";
import { SendOutlined, DeliveredProcedureOutlined } from "@ant-design/icons";
import dayjsGenerateConfig from "rc-picker/lib/generate/dayjs";
import generatePicker from "antd/es/date-picker/generatePicker";
import "antd/es/date-picker/style/index";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isBetween from "dayjs/plugin/isBetween";
import "../css/feedbackPage.css";
import firebase from "firebase";
import star from "../Images/star.png";
import { parse, unparse } from "papaparse";
import axios from "axios";
import downloadCSV from "../utils/downloadCSV";
import {
  adminNum,
  sortCriteria,
  Users,
  userPhone,
  v2_userChats,
  feedback_templates,
  Feedbacks,
  feedback_statistics,
} from "../utils/contants";

const { Option } = Select;
const DatePicker = generatePicker(dayjsGenerateConfig);
dayjs.extend(customParseFormat);
dayjs.extend(isSameOrBefore);
dayjs.extend(isBetween);

export default function FeedbackPage({
  setSelectedContact,
  setSelectedChatId,
  setSelectedPage,
}) {
  const history = useHistory();
  const [uploadedFile, setUploadedFile] = useState(null);
  const [userTemplates, setUserTemplates] = useState([]);
  const [startDate, setStartDate] = useState(dayjs());
  const [endDate, setEndDate] = useState(dayjs());
  const [feedbackDataSource, setFeedbackDataSource] = useState([]);
  const [ratingCounts, setRatingCounts] = useState([0, 0, 0, 0, 0]);
  const [selectedTemplate, setSelectedTemplate] = useState(-1);
  const [feedback_data, setfeedback_data] = useState([]);
  const [feedback_dates, setfeedback_dates] = useState([]);
  const [feedbackSentCounts, setfeedbackSentCounts] = useState(0);
  const [feedbackReceivedCounts, setfeedbackReceivedCounts] = useState(0);
  let [status, setStatus] = useState("feedback_received");

  const sortByParam = (param, x, y) => {
    if (param === sortCriteria.DATE)
      return dayjs(x.date, "DD-MM-YYYY").isSameOrBefore(
        dayjs(y.date, "DD-MM-YYYY")
      )
        ? -1
        : 1;
    else if (param === sortCriteria.NAME) return x.name.localeCompare(y.name);
    else if (param === sortCriteria.ASC_RATING)
      return x.ratingCount - y.ratingCount;
    else if (param === sortCriteria.DES_RATING)
      return y.ratingCount - x.ratingCount;
    else return 0;
  };

  useEffect(() => {
    setSelectedPage("feedback");
  }, [setSelectedPage]);

  const getUser = async (phone) => {
    const promise = await firebase
      .database()
      .ref(`${Users}`)
      .orderByChild(`${userPhone}`)
      .equalTo(phone)
      .once("value");
    let obj = {};
    Object.keys(promise.val()).forEach((user) => {
      obj = promise.val()[user];
    });
    return obj;
  };

  const opfirstWordsonversation = useCallback(
    async (phone) => {
      const client = await getUser(phone);
      const admin = await getUser(adminNum);
      const chatPromise = await firebase
        .database()
        .ref(`${v2_userChats}/${admin.id}/${client.id}`)
        .once("value");
      const chatId = chatPromise.val()?.chatId;
      setSelectedContact(client);
      setSelectedChatId(chatId);
      setSelectedPage("conversations");
      history.push("/dashboard/conversations");
    },
    [history, setSelectedContact, setSelectedChatId, setSelectedPage]
  );

  useEffect(() => {
    const fetchData = async () => {
      const phoneNum = localStorage.getItem("phoneNum");
      const [templateSnapshot, feedbackSnapshot] = await Promise.all([
        firebase
          .database()
          .ref(`${feedback_templates}/${phoneNum}`)
          .once("value"),
        firebase.database().ref(`${Feedbacks}/${phoneNum}`).once("value"),
      ]);
      // console.log( firebase.database().ref(`"feedback_statistics"/${phoneNum}`).once('value'))
      firebase
        .database()
        .ref(`${feedback_statistics}/${phoneNum}`)
        .orderByChild("ts")
        .once("value", (snapshot) => {
          if (snapshot.val()) {
            setfeedback_dates(Object.getOwnPropertyNames(snapshot.val()));
            setfeedback_data(snapshot.val());
          }
        });

      let counter = 0;
      const templates = templateSnapshot.val();
      const feedbacks = [];
      const ratings = [0, 0, 0, 0, 0];
      feedbackSnapshot.forEach((docSnapshot) => {
        docSnapshot.forEach((patientSnapshot) => {
          const data = patientSnapshot.val();
          if (data.rating !== undefined) {
            feedbacks.push({
              key: counter.toString(),
              date: data.date,
              name: (
                <div
                  className="table-name"
                  onClick={() => {
                    opfirstWordsonversation(patientSnapshot.key);
                  }}
                >
                  {data.name}
                </div>
              ),
              status: (
                <Select
                  defaultValue={handleInitialStatus(data.open)}
                  onChange={(value) =>
                    handleStatus(docSnapshot.key, patientSnapshot.key, value)
                  }
                  style={{ minWidth: "80px" }}
                >
                  <Option value="Open">Open</Option>
                  <Option value="Closed">Closed</Option>
                </Select>
              ),
              phoneNumber: patientSnapshot.key,
              ratingCount: Number(data.rating),
              rating: (
                <div>
                  {getStars(Number(data.rating)).map((element) => element)}
                </div>
              ),
            });
            counter++;
          }
        });
      });
      setUserTemplates(templates);
      setFeedbackDataSource(feedbacks);
      setRatingCounts(ratings);
    };

    fetchData();
  }, [opfirstWordsonversation]);
  // console.log(feedback_data);
  // console.log(feedback_dates);
  useEffect(() => {
    var feedback_received = 0;
    var feedback_sent = 0;
    feedback_dates.forEach((date) => {
      var Date_1 = dayjs(startDate).format("DD/MM/YYYY");
      var Date_2 = dayjs(endDate).format("DD/MM/YYYY");
      var Date_to_check = date;

      let D_1 = Date_1.split("/");
      let D_2 = Date_2.split("/");
      let D_3 = Date_to_check.split("-");

      var d1 = new Date(D_1[2], parseInt(D_1[1]) - 1, D_1[0]);
      var d2 = new Date(D_2[2], parseInt(D_2[1]) - 1, D_2[0]);
      var d3 = new Date(D_3[2], parseInt(D_3[1]) - 1, D_3[0]);

      if (d3 >= d1 && d3 <= d2) {
        for (const [key, value] of Object.entries(feedback_data)) {
          if (key === date) {
            feedback_received += value.received;
            feedback_sent += value.sent;
          }
        }
      }
    });

    setfeedbackSentCounts(feedback_sent);
    setfeedbackReceivedCounts(feedback_received);
    const newRatings = [0, 0, 0, 0, 0];
    feedbackDataSource.forEach((element) => {
      let startingDate = dayjs(startDate).format("DD/MM/YYYY");
      let endingDate = dayjs(endDate).format("DD/MM/YYYY");
      // if(dayjs(element.date).isBetween( startingDate , endingDate , null, '[]')) {
      //     console.log(element.date, startingDate, endingDate)
      //     const currRating = Math.floor(Number(element.ratingCount));
      //     if (currRating !== undefined && !isNaN(currRating) && currRating > 0 && currRating <= 5) {
      //         newRatings[currRating - 1] += 1;
      //     }
      // }
      var Date_1 = startingDate;
      var Date_2 = endingDate;
      var Date_to_check = element.date;

      let D_1 = Date_1.split("/");
      let D_2 = Date_2.split("/");
      let D_3 = Date_to_check.split("-");

      var d1 = new Date(D_1[2], parseInt(D_1[1]) - 1, D_1[0]);
      var d2 = new Date(D_2[2], parseInt(D_2[1]) - 1, D_2[0]);
      var d3 = new Date(D_3[2], parseInt(D_3[1]) - 1, D_3[0]);

      if (d3 >= d1 && d3 <= d2) {
        const currRating = Math.floor(Number(element.ratingCount));
        if (
          currRating !== undefined &&
          !isNaN(currRating) &&
          currRating > 0 &&
          currRating <= 5
        ) {
          newRatings[currRating - 1] += 1;
        }
      }
    });
    setRatingCounts(newRatings);
  }, [startDate, endDate, feedbackDataSource, feedback_dates, feedback_data]);

  const handleFileUpload = (info) => {
    if (info.file.status === "uploading")
      setUploadedFile(info.file.originFileObj);
  };

  const handleInitialStatus = (Open) => {
    if (Open === true) {
      return "Open";
    }
    if (Open === false) {
      return "Closed";
    }
  };
  const handleFeedbackStatus = (value) => {
    if (value === "feedback_received") {
      setStatus("feedback_received");
    }
    if (value === "awaiting_feedback") {
      setStatus("awaiting_feedback");
    }
  };

  const handleStatus = (doc, phone, value) => {
    const phoneNum = localStorage.getItem("phoneNum");
    const newValue = value !== "Closed";
    firebase
      .database()
      .ref(`${Feedbacks}/${phoneNum}/${doc}/${phone}`)
      .update({ open: newValue });
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleSortParamChange = (value) => {
    const feedbacks = [...feedbackDataSource];
    feedbacks.sort(sortByParam.bind(null, value));
    setFeedbackDataSource(feedbacks);
  };

  const handleTemplateSelect = (index) => {
    index === selectedTemplate
      ? setSelectedTemplate(-1)
      : setSelectedTemplate(index);
  };

  const downloadExampleCSV = (columnStr, event) => {
    event.stopPropagation();
    const params = columnStr !== "" ? columnStr.split("$") : [];
    const fields = [...params, "phone", "receiver_name", "doctor_name"];
    const data = Array(3)
      .fill(0)
      .map(() => fields.map(() => Math.random()));
    const csv = unparse({ fields, data });
    downloadCSV(csv, "feedback-example.csv");
  };

  const handleCSVSend = () => {
    parse(uploadedFile, {
      header: true,
      transformHeader: (header) => header.toLowerCase().replace(/\W/g, "_"),
      dynamicTyping: true,
      skipEmptyLines: true,
      complete: async ({ data }) => {
        if (data.length === 0) return;
        const header_ = {
          headers: {
            "app-name": "prescribe-chatbot-admin",
          },
        };
        const request = {
          data,
          message: userTemplates[selectedTemplate].template,
          params: userTemplates[selectedTemplate].params,
          sender: localStorage.getItem("phoneNum"),
          template_id: userTemplates[selectedTemplate].template_id,
        };

        try {
          const res = await axios.post(
            "https://prescribe-message-backend.herokuapp.com/send-bulk-feedback-message",
            request,
            header_
          );
          if (res.data && res.data.success) {
            Modal.success({
              title: "Success!",
              content:
                "Your messages have been queued and will reach your selected contacts.",
            });
          }
        } catch (error) {
          Modal.error({
            title: "Oops!",
            content: "An error occured:" + error.response.data.error,
          });
        } finally {
          setUploadedFile(null);
          setSelectedTemplate(-1);
        }
      },
    });
  };
  const handleQueryExport = () => {
    let phoneNum = localStorage.getItem("phoneNum");
    let startingDate = dayjs(startDate).format("YYYY-MM-DD");
    let endingDate = dayjs(endDate).format("YYYY-MM-DD");

    function toTimestamp(strDate) {
      var date = new Date(strDate.split(" ").join("T"));
      return date.getTime() / 1000;
    }
    let startingTS = toTimestamp(startingDate);
    let endingTS = toTimestamp(endingDate);

    function findFirstOccurance(str) {
      const matches = str.split('"');
      return matches[1] ? matches[1] : str;
    }

    const feedbackStatus = findFirstOccurance(status);

    var phoneNum2 = phoneNum.match(/\d+/g).map(Number);

    const header_ = {
      headers: {
        "app-name": "prescribe-chatbot-admin",
      },
    };

    try {
      axios
        .get(
          `https://prescribe-message-backend.herokuapp.com/feedback-data?start=${startingTS}&end=${endingTS}&status=${feedbackStatus}&hospitalNumber=${phoneNum2[0]}`,
          header_
        )
        .then((res) => {
          const client = res.data.clients;
          Modal.success({
            title: "Success!",
            content: "Your data is Exported.",
          });
          const fields = [
            "date",
            "doctor_name",
            "name",
            "receiver_name",
            "phone",
            "status",
          ];
          const data = client.map((obj) => 
            [
              `"${obj.date}"`,
              obj.doctor_name,
              obj.name,
              obj.name,
              obj.phone,
              obj.status,
            ]
          );
          const csv = unparse({ fields, data });
          downloadCSV(csv, "Feedback-export.csv");
        });
    } catch (error) {
      Modal.error({
        title: "Oops!",
        content: "An error occured:" + error.response.data.error,
      });
    }
  };
  const getStars = (count) => {
    const result = [];
    for (let i = 0; i < count; ++i) {
      result.push(
        <img
          style={{ marginRight: "4px" }}
          width="10px"
          height="10px"
          src={star}
          alt=""
        ></img>
      );
    }
    return result;
  };

  const fileSelector = (
    <Upload
      onChange={handleFileUpload}
      showUploadList={false}
      accept=".csv, .xlsx"
    >
      <Button className="file-upload-btn">Select File</Button>
    </Upload>
  );

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      title: "Rating",
      dataIndex: "rating",
      key: "rating",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Go to Convo",
      dataIndex: "GoToConvo",
      key: "GoToConvo",
    },
  ];

  let totalRatings = 0;
  for (let i = 0; i < ratingCounts.length; ++i) {
    if (ratingCounts[i] !== undefined && !isNaN(ratingCounts[i])) {
      totalRatings += ratingCounts[i];
    }
  }

  return (
    <Row className="main-feedback-container" gutter={16}>
      <Col span={18}>
        <div className="left-pane-container">
          Select a template to share as message
          <div className="file-input-container">
            <Input
              addonBefore={fileSelector}
              className="file-input-wrapper"
              value={uploadedFile === null ? "" : uploadedFile.name}
            />
            <Button
              disabled={uploadedFile === null || selectedTemplate === -1}
              className="send-btn"
              onClick={handleCSVSend}
            >
              Send
              <SendOutlined />
            </Button>
          </div>
          <Row gutter={[16, 16]} className="template-container">
            {userTemplates &&
              userTemplates.map((template, i) => (
                <Col span={12} key={template.template_id}>
                  <div
                    className="template-item"
                    onClick={handleTemplateSelect.bind(null, i)}
                    style={{
                      backgroundColor:
                        selectedTemplate === i ? "white" : "#eeeeee",
                    }}
                  >
                    <div>
                      <span>Template:</span>
                      <br />
                      <br />
                      {template.template.split("\\n").map((item) => (
                        <div>
                          <span>{item}</span>
                          <br />
                          <br />
                        </div>
                      ))}
                    </div>
                    <br />
                    <br />
                    <div>
                      <span>Columns: </span>
                      {/* <span>{template.params}</span><br /><br /><br /> */}
                      {template.params.split("$").map((item) => (
                        <span>{item},</span>
                      ))}
                    </div>
                    <br />
                    <br />
                    <div>
                      <span>Example:</span>
                      <br />
                      <br />
                      {/* <span>{template.example}</span><br /><br /><br /> */}
                      {template.example.split("\\n").map((item) => (
                        <div>
                          <span>{item}</span>
                          <br />
                          <br />
                        </div>
                      ))}
                    </div>

                    {selectedTemplate === i && (
                      <div
                        onClick={downloadExampleCSV.bind(
                          null,
                          userTemplates[i].params
                        )}
                        className="csv-text"
                      >
                        Download Example CSV
                      </div>
                    )}
                  </div>
                </Col>
              ))}
          </Row>
          <div className="table-container">
            <span>Review History</span>
            <Select
              defaultValue="date"
              onChange={handleSortParamChange}
              style={{ minWidth: "150px" }}
            >
              <Option value={sortCriteria.ASC_RATING}>Ascending Ratings</Option>
              <Option value={sortCriteria.DES_RATING}>
                Descending Ratings
              </Option>
              <Option value={sortCriteria.DATE}>Date</Option>
              <Option value={sortCriteria.NAME}>Name</Option>
            </Select>
          </div>
          <Table
            className="table-wrapper"
            dataSource={feedbackDataSource}
            columns={columns}
            showHeader={false}
            pagination={false}
          />
        </div>
      </Col>
      <Col span={6}>
        <div className="right-pane-container">
          <span className="right-pane-header">Feedback Overview</span>
          <div className="date-container">
            <div>
              <div>From:</div>
              <DatePicker value={startDate} onChange={handleStartDateChange} />
            </div>
            <div>
              <div>To:</div>
              <DatePicker value={endDate} onChange={handleEndDateChange} />
            </div>
          </div>

          {/* <div className="feedback-stats">
                        <span>Total Queries Sent</span>
                        <span>300</span>
                    </div>
                    <div className="feedback-stats">
                        <span>Total Reponses</span>
                        <span>210</span>
                    </div> */}
          <div style={{ marginTop: "20px", "white-space": "nowrap" }}>
            <Select
              defaultValue="Feedback Recieved"
              onChange={handleFeedbackStatus}
              style={{ Width: "100px", fontSize: "15px" }}
            >
              <Option value="feedback_received">Feedback Recieved</Option>
              <Option value="awaiting_feedback">Awaiting Feedback</Option>
            </Select>
            <DeliveredProcedureOutlined style={{ marginLeft: "10px" }} />
            <button
              onClick={handleQueryExport}
              style={{ fontSize: "16px" }}
              className="export-btn"
            >
              Export
            </button>
          </div>

          <div className="rating">
            <div className="feedback-stats">
              <span>Feedback Sent</span>
              <span style={{ color: "#3fc1c9" }}>{feedbackSentCounts}</span>
            </div>
            <div className="feedback-stats">
              <span>Feedback Received</span>
              <span style={{ color: "#3fc1c9" }}>{feedbackReceivedCounts}</span>
            </div>
          </div>

          {ratingCounts.map((_, i) => (
            <div className="rating">
              <div className="feedback-stats">
                <span>{getStars(5 - i).map((element) => element)}</span>
                <span style={{ color: i > 1 ? "#ff8a80" : "#3fc1c9" }}>
                  {ratingCounts[4 - i]}
                </span>
              </div>
              <div className="feedback-stats">
                <span>{5 - i} star rating %</span>
                <span style={{ color: i > 1 ? "#ff8a80" : "#3fc1c9" }}>
                  {totalRatings !== 0
                    ? Math.floor((ratingCounts[4 - i] * 100) / totalRatings)
                    : 0}
                  %
                </span>
              </div>
            </div>
          ))}
        </div>
      </Col>
    </Row>
  );
}
