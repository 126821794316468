import React, { useState } from "react";
import { Button, Form, Input, Row, Col, Card } from "antd";
import { MailOutlined, LockOutlined } from "@ant-design/icons";
import firebase from "../Firebase";
import side_image from "../Images/left_side_image.jpg";
import logo from "../Images/logo.png";
import { sendAmplitudeData, setAmplitudeUserId } from "../utils/amplitudeFunctions";
import { LOGGED_IN } from "../utils/amplitudeEvents";
import {
    adminNum,
	adminUID,
	UserCredentials,
	Users,
	userPhone,
	pwa_token_list
  } from "../utils/contants";

function Login(props) {
	const [loading, setLoading] = useState(false);

	const login = async (email, password) => {
		try {
			const result = await firebase.auth().signInWithEmailAndPassword(email, password);
			if (result) {
				const dbRef = firebase.database().ref(`${UserCredentials}`);
				const adminSnapshot = await dbRef.orderByChild("email").equalTo(email).once("value");
				const adminData = Object.values(adminSnapshot.val())[0];

				localStorage.setItem("phoneNum", adminData.phoneNumber);
				localStorage.setItem("hospitalName", adminData.hospitalName);
				localStorage.setItem("adminEmail", adminData.email);
				localStorage.setItem("adminName", adminData.name);
				localStorage.setItem("adminUID", adminData.uid);
				localStorage.setItem("carrier", adminData.carrier);
				localStorage.setItem("token", result.user.refreshToken);
				if (adminData.department) {
					localStorage.setItem("adminDepartment", adminData.department);
				}

				setAmplitudeUserId(adminData.email);
				sendAmplitudeData(LOGGED_IN, {
					adminName: adminData.name,
					hospitalName: adminData.hospitalName,
				});

				return true;
			}
		} catch (error) {
			alert(error.message);
		} finally {
			setLoading(false);
		}
	};

	const addTokenToDB = async (token) => {
		if (adminNum) {
			const adminSnapshot = await firebase.database().ref(`${Users}`).orderByChild(`${userPhone}`).equalTo(adminNum).once("value");
	
			if (adminSnapshot.exists()) {
				const adminId = Object.values(adminSnapshot.val())[0].id;
				firebase.database().ref(`${pwa_token_list}/${adminId}/${adminUID}/${token}`).set(true);
			}
		}
	}

	const handleSubmit = async (values) => {
		setLoading(true);
		const isLoginSuccessful = await login(values.email, values.password);
		if (isLoginSuccessful) {
			const message = firebase.messaging();
			if (Notification.permission !== 'granted') {
				Notification.requestPermission().then((permission) => {
					if (permission === 'granted') {
						message.getToken().then((data) => {
							localStorage.setItem("notificationToken", data);
							addTokenToDB(data);
						}).catch(err => {
							console.log(err);
						});
					}
				});
			} else {
				message.getToken().then((data) => {
					localStorage.setItem("notificationToken", data);
					addTokenToDB(data);
				}).catch(err => {
					console.log(err);
				});
			}
			window.location.reload();
		} else {
			alert("Login Failed Please Try Again");
		}
	};

	return (
		<div className="main_form_container">
			<Card className="main_container_card">
				<div className="card_contents_form">
					<div className="side_image">
						<img id="side_image" src={side_image} alt="Test"></img>
					</div>
					<Form
						size="large"
						layout="vertical"
						name="Login"
						onFinish={handleSubmit}
						className="form-container">
						<div className="logo">
							<img id="logo" src={logo} alt="Logo"></img>
						</div>
						<div id="title_login">Login Into Account</div>
						<br />
						<Form.Item
							name="email"
							rules={[
								{
									required: true,
									message: "Please input your E-Mail!",
								},
								{
									type: "email",
									message: "Please enter a valid email",
								},
							]}>
							<Input placeholder="E-Mail" addonBefore={<MailOutlined />} />
						</Form.Item>
						<br />
						<Form.Item
							name="password"
							rules={[
								{
									required: true,
									message: "Please input your password!",
								},
								{
									type: "string",
									min: 8,
									message: "Password must be at least 8 characters long"
								},
							]}>
							<Input.Password placeholder="Password" addonBefore={<LockOutlined />} />
						</Form.Item>
						<Form.Item>
        					<a className="login-form-forgot" href="/verification">
        			 		Reset password
       						</a>
     				 	</Form.Item>

						<Row justify="end" align="middle" style={{ paddingTop: "2em" }}>
							<Col span={12} style={{ textAlign: "right" }}>
								<Form.Item style={{ marginBottom: "0px" }}>
									<Button
										style={{ width: "100%" }}
										type="primary"
										size="large"
										htmlType="submit"
										loading={loading}>
										Log In
									</Button>
								</Form.Item>

							</Col>
						</Row>
					</Form>
				</div>
			</Card>
		</div>
	);
}

export default Login;
