import ReportAnalytics from "./ReportAnalytics";
import firebase from "firebase";
import '../css/reportPage.css';
import {
    adminNum,
    intent_report,
  } from "../utils/contants";

class QueryAnalytics extends ReportAnalytics {
    constructor(props) {
        super(props);
        this.state = {
            queryData: {},
            filteredQueryData: []
        }
    }

    async componentDidMount() {
        const intentSnapshot = await firebase.database().ref(`${intent_report}/${adminNum}`).once('value');
        const intentData = {};
        intentSnapshot.forEach(dateSnapshot => {
            const dateData = dateSnapshot.val();
            Object.keys(dateData).forEach(intent => {
                if(intent in intentData) intentData[intent] += dateData[intent];
                else intentData[intent] = dateData[intent];
            });
        });
        this.setState({
            queryData: intentSnapshot.val() || {},
            filteredQueryData: Object.entries(intentData)
        });
        this.props.setData(Object.entries(intentData));
    }

    shouldComponentUpdate() {
        return true;
    }

    componentDidUpdate(prevProps) {
        if(prevProps.filterParam !== this.props.filterParam || prevProps.startDate !== this.props.startDate || prevProps.endDate !== this.props.endDate) {
            const filteredData = {};
            Object.entries(this.state.queryData).forEach(([date, data]) => {
                if(this.filterData(this.props.filterParam, date)) {
                    Object.keys(data).forEach(intent => {
                        if(intent in filteredData) filteredData[intent] += data[intent];
                        else filteredData[intent] = data[intent];
                    });
                }
            });
            this.setState({ filteredQueryData: Object.entries(filteredData) });
            this.props.setData(Object.entries(filteredData));
        }
    }

    render() {
        return (
            <div className="query-stats-container">
                {this.state.filteredQueryData.map(([ intent, frequency ]) => (
                    <div className="query-stats-item">
                        <span className="query-stats-item-title">{intent}</span>
                        <span className="query-stats-item-count">{frequency}</span>
                    </div>
                ))}
            </div>
        )
    }
}

export default QueryAnalytics;