import React, { Component } from "react";
import Graph from "./Graph";
import firebase from "../Firebase.js";
import {
	subscriptionData,
	chatAnalytics,
	
  } from "../utils/contants"

export default class AnalyticsPage extends Component {
	constructor(props) {
		super(props);
		this.totalSessionMessages = 0;
		this.totalTemplateMessages = 0;
		this.state = {
			graphData: {},
			receivedCount: 0,
			sentCount: 0,
			autoReplyCount: 0,
			adminMessagesCount: 0,
			templateMessageCount: 0,
			sessionMessages: 0,
			uniqueClientCount: 0
		};
	}

	componentDidMount() {
		this.getSubscriptionData(this.props.adminNum);
	}

	getSubscriptionData = (phoneNum) => {
		firebase
			.database()
			.ref(`${subscriptionData}/${phoneNum}`)
			.once("value", (snapshot) => {
				const obj = snapshot.val();
				if (obj) {
					this.totalTemplateMessages = obj.template_messages;
					this.totalSessionMessages = obj.session_messages;
				}
				this.getAnalyticsData(this.props.adminId);
			});
	}

	getAnalyticsData = (id) => {
		var analyticsData = [];
		firebase
			.database()
			.ref(`${chatAnalytics}/${id}`)
			.once("value", (snapshot) => {
				const obj = snapshot.val();
				for (var key of Object.keys(obj)) {
					const dateArr = key.split("-");
					if (dateArr.length < 3) continue;
					const day = parseInt(dateArr[0]);
					const month = parseInt(dateArr[1]);
					const year = parseInt(dateArr[2]);
					const dayFromRef = (year - 2019) * 365 + (month - 1) * 12 + day;
					const dateObj = new Date(year, month - 1, day);
					const currObj = {
						date: key,
						ts: dateObj.getTime(),
						dayFromReference: dayFromRef,
						data: obj[key],
					};
					analyticsData.push(currObj);
				}
				this.arrangeGraphData(analyticsData);
			});
	};

	arrangeGraphData = (analyticsData) => {
		analyticsData.sort((a, b) => {
			return a.ts - b.ts;
		});
		let adminMessages = [];
		let autoReply = [];
		let received = [];
		let sent = [];
		let sessionMessages = [];
		let templateMessages = [];
		let uniqueClients = [];
		let dailyX = [];
		let receivedCount = 0,
			sentCount = 0,
			autoReplyCount = 0,
			adminMessagesCount = 0,
			templateMessageCount = 0,
			uniqueClientCount = 0;
		analyticsData.forEach((x) => {
			dailyX.push(x.date);
			adminMessages.push(parseInt(x.data.admin_messages));
			autoReply.push(parseInt(x.data.auto_reply));
			received.push(parseInt(x.data.received));
			sent.push(parseInt(x.data.sent));
			sessionMessages.push(parseInt(x.data.session_messages));
			templateMessages.push(parseInt(x.data.template_messages));
			uniqueClients.push(parseInt(x.data.unique_client));
			receivedCount += parseInt(x.data.received);
			sentCount += parseInt(x.data.sent);
			autoReplyCount += parseInt(x.data.auto_reply);
			adminMessagesCount += parseInt(x.data.admin_messages);
			templateMessageCount += parseInt(x.data.template_messages);
			uniqueClientCount += parseInt(x.data.unique_client);
		});

		let graphData = this.setUpGraphData(
			dailyX,
			adminMessages,
			autoReply,
			received,
			sent,
			sessionMessages,
			templateMessages,
			uniqueClients
		);

		let templateCountText = "";
		if (this.totalTemplateMessages !== 0) {
			templateCountText = `${templateMessageCount}/${this.totalTemplateMessages}`;
		} else {
			templateCountText = templateMessageCount;
		}

		this.setState({
			graphData: graphData,
			receivedCount: receivedCount,
			sentCount: sentCount,
			autoReplyCount: autoReplyCount,
			adminMessagesCount: adminMessagesCount,
			templateMessageCount: templateCountText,
			sessionMessages: receivedCount + sentCount - templateMessageCount,
			uniqueClientCount
		});
	};

	setUpGraphData = (dailyX, adminMessages, autoReply, received, sent, sessionMessages, templateMessages, uniqueClients) => {
		console.log(uniqueClients);
		const graphData = {
			labels: dailyX,
			datasets: [
				{
					label: "Admin Messages",
					data: adminMessages,
					fill: false,
					backgroundColor: "rgb(255, 99, 132)",
					borderColor: "rgb(255, 99, 132)",
				},
				{
					label: "Auto Reply",
					data: autoReply,
					fill: false,
					backgroundColor: "rgb(75, 192, 192)",
					borderColor: "rgb(75, 192, 192)",
				},
				{
					label: "Received Messages",
					data: received,
					fill: false,
					backgroundColor: "rgb(99, 255, 132)",
					borderColor: "rgb(99, 255, 132)",
				},
				{
					label: "Sent Messages",
					data: sent,
					fill: false,
					backgroundColor: "rgb(175, 99, 255)",
					borderColor: "rgb(175, 99, 255)",
				},
				{
					label: "Session Messages",
					data: sessionMessages,
					fill: false,
					backgroundColor: "rgb(75, 99, 132)",
					borderColor: "rgb(75, 99, 132)",
				},
				{
					label: "Template Messages",
					data: templateMessages,
					fill: false,
					backgroundColor: "rgb(255, 193, 22)",
					borderColor: "rgb(255, 193, 22)",
				},
				{
					label: "Unique Clients",
					data: uniqueClients,
					fill: false,
					backgroundColor: "rgb(255, 75, 22)",
					borderColor: "rgb(255, 75, 22)",
				},
			],
		};
		return graphData;
	};

	render() {
		return (
			<>
				<div style={{ overflowY: "scroll", height: "calc(100vh - 64px)" }}>
					<div className="paper_container">
						<div className="messages_recieved">
							<div>Total Messages Received</div>
							<div>
								<b>{this.state.receivedCount}</b>
							</div>
						</div>
						<div className="total_session_messages_paper">
							<div>Total Session Messages</div>
							<b>{this.state.sessionMessages}</b>
						</div>
						<div className="messages_sent">
							<div>Total Messages Sent</div>
							<div>
								<b>{this.state.sentCount}</b>
							</div>
						</div>
						<div className="session_autoreplies_paper">
							<div>Total Auto Replies</div>
							<div>
								<b>{this.state.autoReplyCount}</b>
							</div>
						</div>
						<div className="template_messages">
							<div>Total Template messages</div>
							<div>
								<b>{this.state.templateMessageCount}</b>
							</div>
						</div>
					</div>
					<div className="graph_container">
						<div className="analytics_graph">
							<Graph graphData={this.state.graphData} />
						</div>
						<div className="response_ranking_paper">Response Ranking</div>
					</div>
				</div>
			</>
		);
	}
}
