import React, { Component, createRef } from "react";
import { RiSendPlane2Fill } from "react-icons/ri";
import Popup from "./Popup";
import firebase from "../Firebase.js";
import { Form, Switch, Tooltip, Input, Col, Row, Button, Spin, Modal, message } from "antd";
import { InfoCircleOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import axios from "axios";
import OpenConvPopup from "./OpenConvPopup";
import QuickReplies from "./QuickReplies";
import OpenConversationAssign from "./OpenConversationAssign";
import Message from "./Message";

import {
  AUTO_CHANGED,
  MESSAGE_SENT,
  QUICK_REPLY_SELECTED,
  REPLIED_TO_MESSAGE,
  MESSAGE_RECEIVED,
} from "../utils/amplitudeEvents";
import {
  adminUID ,
  adminName,
  adminNum,
  adminDepartment,
  carrier,
  header_,
  UserCredentials,
  Users,
  v2_userChats,
  v2_agentWiseChats,
  v2_assignedChats,
  chatMessages,
  closed,
  open,
  ongoing,
  child_added,
  
} from "../utils/contants";
import { sendAmplitudeData } from "../utils/amplitudeFunctions";
import { myContext } from "./myContext";
import { AiFillCloseCircle } from "react-icons/ai";
import { unparse as parseToCSV } from "papaparse";
import downloadCSV from "../utils/downloadCSV";
import { intToRGB, hashCode } from "../utils/colorUtils";
import Avatar from "antd/lib/avatar/avatar";
import { algoliaIndex } from "../Algolia";
import SingleTemplateMessages from "./SingleTemplateMessage";
import ConversationStatus from "./ConversationStatus";

var value;
const { confirm } = Modal;

export default class OpenConversation extends Component {
  constructor(props) {
    super(props);
    this.messagesEnd = createRef();
    this.messagesStart = createRef();
    this.searchedMessageRef = createRef();
    this.formRef = createRef(null);
    this.messageInputRef = createRef(null);
    this.replyMessageHolder = "";
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      messageList: [],
      lastMessageDate: "",
      lastMessageTime: "",
      contact: {},
      chatId: "default",
      contactSelected: false,
      checked: false,
      isSending: false,
      blocked: false,
      attendedById: "none",
      tags: "",
      chips: [],
      replyingTo: "",
      dbChips: "",
      admiDbID: 0,
      chatObj: {},
      chatStatus: "closed"
      
    };
  }

  handleSubmit(values) {
    let message = values.message ? values.message.trim() : "";

    if (message === "") {
      window.alert("Empty messages aren't allowed");
      return;
    }
    this.setState({ isSending: true });
    this.sendMessage(message);
    this.formRef.current.setFieldsValue({ message: "" });
  }

  handleChange(e) {
    e.preventDefault();
    this.setState({
      message: e.target.value,
    });
  }

  componentDidMount() {
    // console.log("Open Converstation Props", this.props);
    this.getAdminChipTemplates(localStorage.getItem("adminUID"));
  }

  componentDidUpdate() {
    if (
      this.props.chatId !== "no-select" &&
      (!this.state.contactSelected || this.state.chatId !== this.props.chatId)
    ) {
      this.getContact(this.props.contact.id);
      // this.addChatListener(this.props.chatId);
    }
  }

  componentWillUnmount() {
    // firebase
    //   .database()
    //   .ref(`v2_userChats/${this.context.adminId}/${this.props.contact.id}`)
    //   .off();
    // firebase.database().ref(`chatMessages/${this.props.chatId}`).off();
  }

  updateTags = (newTags) => {
    this.setState(
      {
        tags: newTags,
      },
      () => {
        console.log(this.state.tags);
      }
    );
  };

  getAdminChipTemplates(uid) {
    // console.log("Chip Fn");
    firebase
      .database()
      .ref(`${UserCredentials}`)
      .orderByChild("uid")
      .equalTo(uid)
      .once("value", (snapshot) => {
        snapshot.forEach((childSnapshot) => {
			
          value = childSnapshot.val();
          // console.log(value);
        });
        this.setState(
          {
            dbChips: value.chips,
            admiDbID: value.dbID,
          },
          () => {
            var str = this.state.dbChips;
            var res = str.split("~").filter((str) => str !== "");
            // console.log(res);
            this.setState({
              chips: res,
            });
          }
        );
      });
  }

  getContact(id) {
    firebase
      .database()
      .ref(`${Users}/${id}`)
      .once("value", (snapshot) => {
        var curr_contact = snapshot.val();
        this.setState(
          {
            contact: curr_contact,
            contactSelected: true,
            chatId: this.props.chatId,
            tags: curr_contact.tags,
          },
          () => {
            this.addChatListener(this.props.chatId);
            this.addChatObjListener(id);
          }
        );
      });
  }

  getReplyMessage = (msg, fromMe) => {
    sendAmplitudeData(REPLIED_TO_MESSAGE, { length: msg.length });
    this.replyMessageHolder = msg;
    this.messageInputRef.current.focus();
    this.setState({
      replyingTo: fromMe
        ? "Me"
        : this.state.contact.userDisplayName.split(" ")[0],
    });
  };

  addChatObjListener = (contactId) => {
    firebase
      .database()
      .ref(`${v2_userChats}/${this.context.adminId}/${contactId}`)
      .on("value", (snapshot) => {
        if (this.state.contact.id === contactId) {
          const snapshotVal = snapshot.val();
          if (snapshotVal !== undefined) {
            let tagsString = snapshotVal.tags ? snapshotVal.tags.trim() : "";
            let status = `${closed}`;
            if (tagsString.includes(`${open}`)) status = `${open}`;
            if (tagsString.includes(`${ongoing}`)) status = `${ongoing}`;
            const blocked_ = snapshotVal.blocked === undefined ? false : snapshotVal.blocked;
            this.setState({
              tags: tagsString,
              checked: snapshotVal.auto_reply === "true",
              attendedById: snapshotVal.attendedById,
              chatObj: snapshotVal,
              chatStatus: status,
              blocked: blocked_
            });
          }
        }
      });
  };

  addMessageScrollListener = () => {
    const ref = firebase.database().ref(`${chatMessages}/${this.props.chatId}`);

    const addPreviousMessages = () => {
      ref
        .orderByKey()
        .endAt(this.state.messageList[0].messageKey)
        .limitToLast(20)
        .once("value", (snapshot) => {
          if (snapshot.exists()) {
            const currentChats = Object.entries(snapshot.val());

            // we remove the last message by slicing because it is already there
            const moreMessageList = currentChats
              .slice(0, currentChats.length - 1)
              .map(([key, chat]) => ({
                curr_chat: chat,
                chatId: this.props.chatId,
                fromMe: chat.sentBy === this.context.adminId,
                messageKey: key,
              }));
            this.setState((prevState) => {
              return {
                messageList: [...moreMessageList, ...prevState.messageList],
              };
            });
          }
        });
    };
    const messageScrollObserver = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          // load messages on scroll
          if (this.props.messageIndex === -1) {
            addPreviousMessages();
          }
        }
      },
      { threshold: [0] }
    );

    if (this.messagesStart.current) {
      messageScrollObserver.observe(this.messagesStart.current);
    }
  };

  addChatListener(chatId) {
    const ref = firebase.database().ref(`${chatMessages}/${this.props.chatId}`);
    // const searchedMessageIndex = this.props.messageIndex !== -1 ? this.props.messageIndex : 10;

    ref.endAt().once("value", (snapshot) => {
      if (snapshot.exists()) {
        // const currentChats = Object.entries(snapshot.val());
        const currentChats = snapshot.val();
        var messageList = [];
        for (const key in currentChats) {
          const fromMe = currentChats[key].sentBy === this.context.adminId;
          const read = currentChats[key].read;
          let sentByText = "";
          if (currentChats[key].agentId) {
            const adminName = this.context.adminMap[currentChats[key].agentId]?.name;
            const adminDep = this.context.adminMap[currentChats[key].agentId]?.department;
            if (adminName) {
              sentByText += `Sent by: ${adminName}`;
              if (adminDep) {
                sentByText += `-${adminDep}`;
              }
            }
          }
          messageList.push({
            curr_chat: currentChats[key],
            chatId,
            fromMe,
            sentByText,
            messageKey: key
          });
          if (!fromMe && read === "false") {
            this.markMessageAsRead(chatId, key, currentChats[key]);
          }
        }
        // const messageList = currentChats.map(([key, chat]) => ({
        //   curr_chat: chat,
        //   chatId,
        //   fromMe: chat.sentBy === this.context.adminId,
        //   messageKey: key,
        // }));
        // console.log(messageList.length);
        this.setState(
          {
            messageList,
            lastMessageDate:
              messageList[messageList.length - 1].curr_chat.messageDate,
            lastMessageTime:
              messageList[messageList.length - 1].curr_chat.messageTime,
          },
          () => {
            if (this.props.messageIndex !== -1) {
              this.focusSearchedMessage();
            } else {
              this.scrollToBottom();
            }

            listenForNewMessages();
          }
        );
      }
    });

    // listen for new messages
    const listenForNewMessages = () => {
      ref.limitToLast(1).on(`${child_added}`, (snapshot) => {
        const curr_chat = snapshot.val();
        const messageKey = snapshot.key;
        const fromMe = curr_chat.sentBy === this.context.adminId;

        if (this.state.chatId === chatId) {
          let sentByText = "";
          if (curr_chat.sentBy) {
            const adminName = this.context.adminMap[curr_chat.agentId]?.name;
            const adminDep = this.context.adminMap[curr_chat.agentId]?.department;
            if (adminName) {
              sentByText += `Sent by: ${adminName}`;
              if (adminDep) {
                sentByText += `-${adminDep}`;
              }
            }
          }
          const newMessage = {
            curr_chat,
            chatId,
            fromMe,
            messageKey,
            sentByText
          };

          if (
            this.state.messageList.every(
              ({ messageKey }) => messageKey !== newMessage.messageKey
            )
          ) {
            if (fromMe) {
              sendAmplitudeData(MESSAGE_SENT, {
                sender: adminNum,
                recipient: this.state.contact.userPhone,
                intent: curr_chat.intent,
                value: curr_chat.message,
              });
            } else {
              sendAmplitudeData(MESSAGE_RECEIVED, {
                sender: this.state.contact.userPhone,
                intent: curr_chat.intent,
                value: curr_chat.message,
              });
              this.markMessageAsRead(this.state.chatId, messageKey, curr_chat);
            }
            this.setState((prevState) => {
              return {
                messageList: [...prevState.messageList, newMessage],
                lastMessageDate: curr_chat.messageDate,
                lastMessageTime: curr_chat.messageTime,
              };
            }, this.scrollToBottom);
          }
        }
      });
    };
  }

  markMessageAsRead = (chatId, messageId, obj) => {
    var readByName = adminName;
    if (adminDepartment) {
      readByName += " - " + adminDepartment;
    }
    obj['readBy'] = readByName;
    obj['read'] = "true";
    firebase
      .database()
      .ref(`${chatMessages}/${chatId}/${messageId}`)
      .update(obj);
  }

  handleToggleChange = () => {
    // var autoReplyState;
    sendAmplitudeData(AUTO_CHANGED, {
      action: this.state.checked ? "turn off" : "turn on",
    });

    var attendedById_ = "none";
    var obj = this.state.chatObj;
    if (this.state.checked) {
      // autoReplyState = "false";
      attendedById_ = adminUID;
      var attendedByName = adminName;
      if (adminDepartment) {
        attendedByName += " - " + adminDepartment;
      }
      obj['attendedById'] = adminUID;
      obj['attendedBy'] = attendedByName;
      obj['auto_reply'] = 'false';
      obj['auto_reply_ts'] = Math.round(Date.now() / 1000).toString();
      // firebase
      //   .database()
      //   .ref(`v2_agentWiseChats/${this.state.attendedById}/${this.state.contact.id}`)
      //   .set(null);
      firebase
        .database()
        .ref(`${v2_agentWiseChats}/${adminUID}/${this.state.contact.id}`)
        .set(obj);
      firebase
        .database()
        .ref(`${v2_assignedChats}/${this.context.adminId}/${this.state.contact.id}`)
        .set(obj);
      firebase
        .database()
        .ref(`${v2_userChats}/${this.context.adminId}/${this.state.contact.id}`)
        .set(obj);
      algoliaIndex.partialUpdateObject({
        'objectID': this.state.chatId,
        'attendedById': adminUID
      });
    } else {
      // console.log(this.state.contact.attendedById);
      obj['auto_reply'] = 'true';
      obj['attendedById'] = '';
      obj['attendedBy'] = 'bot';
      obj['auto_reply_ts'] = Math.round(Date.now() / 1000).toString();
      firebase
        .database()
        .ref(
          `${v2_agentWiseChats}/${this.state.attendedById}/${this.state.contact.id}`
        )
        .set(null);
      firebase
        .database()
        .ref(`${v2_assignedChats}/${this.context.adminId}/${this.state.contact.id}`)
        .set(null);
      firebase
        .database()
        .ref(`${v2_userChats}/${this.context.adminId}/${this.state.contact.id}`)
        .set(obj);
      algoliaIndex.partialUpdateObject({
        'objectID': this.state.chatId,
        'attendedById': '-'
      });
      // autoReplyState = "true";
    }
    this.setState({
      checked: !this.state.checked,
      attendedById: attendedById_,
    });
    // this.changeAutoReplyStatus(this.state.contact, autoReplyState);
  };

  sendMessage(message_) {
    const { replyingTo } = this.state;
    const dateAndTime = this.getDateAndTime();
    const messageSender = localStorage.getItem("adminName");
    const isReply = replyingTo !== "";
    const replyingToName = replyingTo === "Me" ? messageSender : "You";
    const finalMsg = isReply
      ? `*${replyingToName}:* ${this.replyMessageHolder}\n\n*${messageSender}*: ${message_}`
      : message_;

    if (isReply) {
      this.replyMessageHolder = "";
      this.setState({ replyingTo: "" });
    }
    // var agentId = this.state.contact.attendedById;
    // if (agentId === undefined) agentId = "";

    const request = {
      sender: adminNum,
      receiver: this.state.contact.userPhone,
      type: "text",
      hospital: this.state.hospital,
      date: dateAndTime.date,
      time: dateAndTime.time,
      carrier,
      payload: {
        message: finalMsg.trim(),
        caption: "",
        admin_id: this.context.adminId,
        client_id: this.state.contact.id,
        chat_id: this.state.chatId,
        agent_id: adminUID,
        client_name: this.state.contact.userDisplayName,
      },
    };

    axios
      .post(
        "https://prescribe-message-backend.herokuapp.com/admin-message-v2",
        request,
        header_
      )
      .finally(() => {
        this.setState({ isSending: false });
      });
  }

  getDateAndTime() {
    var today = new Date();
    var date_ =
      today.getDate() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getFullYear();
    var time_ = today.getHours() + ":" + today.getMinutes();
    const res = {
      date: date_,
      time: time_,
    };
    return res;
  }

  scrollToBottom = () => {
    this.messagesEnd.scrollIntoView();
  };
  focusSearchedMessage = () => {
    this.searchedMessageRef.current?.triggerSearchFocus();
  };

  handleImage(src) {
    window.open(src);
  }

  addTemplateMessage = (message) => {
    const currentTemplateMessages = this.state.chips;
    if (!currentTemplateMessages.includes(message.trim())) {
      const newArr = [...currentTemplateMessages, message];

      this.setState({ chips: newArr });
      const tagString = newArr.join("~");
      const admiDbID = this.state.admiDbID.toString();
      firebase
        .database()
        .ref(`${UserCredentials}`)
        .child(admiDbID)
        .update({ chips: tagString });
    } else {
      window.alert("This message is already present among the templates.");
    }
  };

  deleteTemplateMessage = (message) => {
    var previousChipState = this.state.chips;
    const indexer = previousChipState.indexOf(message);
    if (indexer > -1) {
      previousChipState.splice(indexer, 1);
    }
    this.setState({ chips: previousChipState });
    const tagString = previousChipState.join("~");
    const dbIdState = this.state.admiDbID;
    const dbIdStr = dbIdState.toString();
    firebase
      .database()
      .ref(`${UserCredentials}`)
      .child(dbIdStr)
      .update({ chips: tagString });
  };
  handleReplyClose = () => {
    this.setState({
      replyingTo: "",
    });
  };
  handleChatExport = () => {
    firebase
      .database()
      .ref(`${chatMessages}/${this.props.chatId}`)
      .once("value", (snapshot) => {
        const messageList = Object.values(snapshot.val());
        const { userPhone, userDisplayName } = this.state.contact;
        const messageListReshaped = messageList
          .filter(({ type }) => type === "text")
          .map((chat) => {
            const {
              messageDate,
              messageTime,
              type,
              read,
              sentBy,
              ...commonFields
            } = chat;
            const fromMe = sentBy === this.context.adminId;
            return {
              sender: fromMe ? adminName : userDisplayName,
              phone: fromMe ? adminNum : userPhone,
              ...commonFields,
              timestamp: `${messageDate} ${messageTime}`,
            };
          });
        const fields = Object.keys(messageListReshaped[0]).map(
          (field) => field[0].toUpperCase() + field.slice(1)
        );
        const csvData = parseToCSV({
          fields,
          data: messageListReshaped.map(Object.values),
        });
        downloadCSV(csvData, `Chat-${adminName}-${userPhone}.csv`);
      });
  };

  handleBlock = () => {
    const sendRequest = () => {
      const currentStatus = this.state.blocked;
      const userPhone = this.state.contact?.userPhone;
      if (!userPhone) {
        return;
      }
      const body = {
        hospital: adminNum,
        client: userPhone,
        block: !currentStatus
      };
      axios
        .post(
          "https://prescribe-message-backend.herokuapp.com/block-user",
          body,
          header_
        )
        .then((response) => {
          if (response.data?.success) {
            const blockText = currentStatus ? "unblocked" : "blocked";
            message.success(`Client has been ${blockText}`);
          } else {
            message.error(`Client could not be ${blockText}`);
          }
        })
    }
    const blockText = this.state.blocked ? "unblock" : "block";
    confirm({
      title: `Are you sure you want to ${blockText} this client?`,
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      cancelText: 'No',
      okType: 'danger',
      onOk() {
        sendRequest();
      },
      // onCancel() {
      //   renderCard();
      // }
    });
  }

  render() {
    const isLastMessageByUs =
      this.state.messageList[this.state.messageList.length - 1]?.fromMe;
    if (this.state.contactSelected) {
      return (
        <>
          <div className="top_bar">
            <div className="con_name_bar">
              <Avatar
                style={{
                  backgroundColor: intToRGB(
                    hashCode(this.state.contact.userDisplayName)
                  ),
                }}
              >
                {this.state.contact.userDisplayName[0]}
              </Avatar>
              <div
                style={{
                  paddingLeft: "5px",
                  fontSize: "medium",
                  display: "flex",
                  alignItems: "left",
                  flexDirection: "column"
                }}
              >
                <div>{this.state.contact.userDisplayName}</div>
                <div style={{ fontSize: "14px" }}>
                  +{this.state.contact.userPhone}
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                width: "50%",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              <ConversationStatus
                adminId={this.context.adminId}
                contactId={this.props.contact.id}
                chatId={this.state.chatId}
                status={this.state.chatStatus}
                chatObj={this.state.chatObj}
                style={{ marginRight: "1em" }} />
              <div
                className={
                  this.state.checked ? "switch_toggle_checked" : "switch_toggle"
                }
              >
                <Tooltip
                  placement="left"
                  title="This is turned on automatically after 30 minutes."
                >
                  <InfoCircleOutlined style={{ marginRight: "1em" }} />
                </Tooltip>
                <Switch
                  checked={this.state.checked}
                  onChange={this.handleToggleChange}
                  unCheckedChildren="Auto Off"
                  checkedChildren="Auto On"
                />
              </div>
              <OpenConversationAssign
                id={this.props.chatId}
                contact={this.state.contact}
                lastMessageDate={this.state.lastMessageDate}
                lastMessageTime={this.state.lastMessageTime}
                chatObj={this.state.chatObj}
                attendedById={this.state.attendedById}
              />
              <OpenConvPopup
                contactID={this.props.contact.id}
                chatId={this.state.chatId}
                adminID={this.context.adminId}
                handleChatExport={this.handleChatExport}
                handleBlock={this.handleBlock}
                blocked={this.state.blocked}
              />
            </div>
          </div>
          <div className="conv_content">
            <div
              style={{ float: "left", clear: "both" }}
              ref={this.messagesStart}
            ></div>
            <div style={{ marginTop: "20px" }}>
              {this.state.messageList.map((props, i) => (
                <Message
                  ref={
                    this.state.messageList.length - i - 1 ===
                    this.props.messageIndex
                      ? this.searchedMessageRef
                      : undefined
                  }
                  key={props.messageKey}
                  {...props}
                  getReplyMessage={this.getReplyMessage}
                  isLastMessageByUs={isLastMessageByUs}
                  sentByText={props.sentByText}
                />
              ))}
            </div>
            <div
              style={{ float: "left", clear: "both" }}
              ref={(el) => {
                this.messagesEnd = el;
              }}
            ></div>
          </div>
          <div className="form_input">
            <Form
              ref={this.formRef}
              onFinish={this.handleSubmit}
              style={{ width: "100%", padding: "0.3em 1em" }}
            >
              {this.state.replyingTo !== "" && (
                <Row
                  className="reply-card"
                  justify="space-between"
                  align="middle"
                >
                  <Col
                    span={22}
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    <strong> {this.state.replyingTo}</strong>:{" "}
                    {this.replyMessageHolder}{" "}
                  </Col>
                  <Col span={2} style={{ textAlign: "center" }}>
                    <Button
                      onClick={this.handleReplyClose}
                      className="icon-button"
                      size="small"
                      icon={<AiFillCloseCircle size={22} color="#aaa" />}
                    />
                  </Col>
                </Row>
              )}

              <Row align="middle" style={{ width: "100%" }}>
              <Col span={1.5}>
                  <SingleTemplateMessages
                    isAutoOn={this.state.checked}
                    templateMessages={this.state.chips}
                    addTemplateMessage={this.addTemplateMessage}
                    deleteTemplateMessage={this.deleteTemplateMessage}
                    userPhone={this.state.contact.userPhone}
                    adminNum={localStorage.getItem("phoneNum")}
                    onChoose={(message) => {
                      sendAmplitudeData(QUICK_REPLY_SELECTED, {
                        message,
                        length: message.length,
                      });
                      this.formRef.current.setFieldsValue({ message });
                    }}
                  />
                </Col>
                <Col span={1.5}>
                  <QuickReplies
                    isAutoOn={this.state.checked}
                    templateMessages={this.state.chips}
                    addTemplateMessage={this.addTemplateMessage}
                    deleteTemplateMessage={this.deleteTemplateMessage}
                    onChoose={(message) => {
                      sendAmplitudeData(QUICK_REPLY_SELECTED, {
                        message,
                        length: message.length,
                      });
                      this.formRef.current.setFieldsValue({ message });
                    }}
                  />
                </Col>
                <Col span={1.5}>
                  <Popup
                    isAutoOn={this.state.checked}
                    adminNum={adminNum}
                    contact={this.state.contact}
                    adminId={this.context.adminId}
                    chatId={this.state.chatId}
                    agentId={this.state.contact.attendedById ?? ""}
                  />
                </Col>
                <Col flex="auto">
                  <Form.Item
                    name="message"
                    disabled={this.state.checked}
                    autoFocus={true}
                  >
                    <Input.TextArea
                      style={{
                        borderRadius: "3em",
                        height: "3em",
                      }}
                      ref={this.messageInputRef}
                      disabled={this.state.checked}
                      placeholder={
                        this.state.checked ? "Auto is on" : "Enter Message..."
                      }
                      onKeyPress={(e) => {
                        if (e.key === "Enter" && !e.shiftKey) {
                          e.preventDefault();
                          this.formRef.current.submit();
                        }
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item>
                    <Button
                      className="icon-button"
                      htmlType="submit"
                      disabled={this.state.checked}
                    >
                      {this.state.isSending ? (
                        <Spin size={25} />
                      ) : (
                        <RiSendPlane2Fill size={22} />
                      )}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
        </>
      );
    } else {
      return <div className="none_cont">Please select a contact.</div>;
    }
  }
}

OpenConversation.contextType = myContext;
