import React, { Component } from "react";
import { Card, Row, Space, Avatar } from "antd";
import firebase from "firebase";
import { intToRGB, hashCode } from "../utils/colorUtils";
import {
	Users,
  } from "../utils/contants";

export class KeywordChatWindow extends Component {
	constructor(props) {
		super(props);

		this.state = {
			contact: {},
			loading: true,
		};
		this.mounted = true;
	}

	componentDidMount() {
		this.mounted = true;
		this.getContact(this.props.contactId);
	}

	componentWillUnmount() {
		this.mounted = false;
	}

	handleCardClick = (e) => {
		if (!this.state.loading) {
			this.props.changeDashboardState(this.state.contact, this.props.chatId, this.props.messageIndex);
		}
	};

	getContact = (id) => {
		firebase
			.database()
			.ref(`${Users}/${id}`)
			.once("value", (snapshot) => {
				const contact_ = snapshot.val();
				this.setState({
					contact: contact_,
					loading: false,
				});
			});
	};

	render() {
		const [pre, searchpart, restWord, trail] = this.props.message.split(/<em>(.*)<\/em>(\w+|\s|\W|$)/g);
		return (
			<Card
				title={
					<Row align="middle" justify="space-between">
										<Space>
											<Avatar style={{ backgroundColor: intToRGB(hashCode(this.props.name)) }}>
												{this.props.name[0]}
											</Avatar>
											<strong style={{ color: "#393E46" }}>{this.props.name}</strong>
										</Space>
									</Row>
				}
				hoverable
				style={{ margin: "0.5em" }}
				onClick={this.handleCardClick}>
				<div className="searched-message">
					{pre?.trim() !== "" && <span className="cutout-text">{pre}</span>}
					<mark className="match"> { searchpart + restWord }</mark>
					{trail?.trim() !== "" && <span className="cutout-text">...{trail}</span>}
				</div>
				<div className="searched-message-date-time">
					{this.props.messageDateTime}
				</div>
			</Card>
		);
	}
}

export default KeywordChatWindow;
