import amplitude from "amplitude-js";
const AMPLITUDE_API_KEY = "00be6666842d5c3e57cfa216824896c8";

export const initAmplitude = () => {
	if (process.env.NODE_ENV === "production") {
		amplitude.getInstance().init(AMPLITUDE_API_KEY);
	} else {
		console.log("init amplitude");
	}
};

export const setAmplitudeUserDevice = (installationToken) => {
	amplitude.getInstance().setDeviceId(installationToken);
};

export const setAmplitudeUserId = (userId) => {
	if (process.env.NODE_ENV === "production") {
		amplitude.getInstance().setUserId(userId);
	} else {
		console.log(userId);
	}
};

export const setAmplitudeUserProperties = (properties) => {
	amplitude.getInstance().setUserProperties(properties);
};

export const sendAmplitudeData = (eventType, eventProperties) => {
	if (process.env.NODE_ENV === "production") {
		amplitude.getInstance().logEventWithTimestamp(eventType, eventProperties);
	} else {
		console.log(eventType, eventProperties);
	}
};
