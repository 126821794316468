import axios from "axios";
import { unparse } from "papaparse";
import firebase from "../Firebase";
import dayjs from "dayjs";
import {
	header_,
  } from "./contants";
import { sendAmplitudeData } from "./amplitudeFunctions";
import { IMPORTANT_CHANGED, TAGS_CHANGED } from "./amplitudeEvents";
import downloadCSV from "./downloadCSV";
import { Tag, } from "antd";
import { intToRGB, hashCode } from "./colorUtils";

export const getContactKeysAndTimestamps = async (adminId) => {
	const snapshot = await firebase.database().ref(`v2_userChats/${adminId}`).once("value");
	const snapshotArray = Object.entries(snapshot.val());
	const contactKeysAndTs = snapshotArray.map((obj) => [obj[0], obj[1].timestamp]);
	return contactKeysAndTs;
};
export const AllTags = ({ tags, onCloseTags }) => {
	return tags
		.split("~")
		.filter((str) => str !== "")
		.map((element) => {
			const hexColor = intToRGB(hashCode(element));
			const isClosable = element !== 'open' && element !== 'closed' && element !== 'ongoing';
			return (
				<Tag key={element} closable={isClosable} onClose={() => onCloseTags(element)} color={hexColor}>
					{element}
				</Tag>
			);
		});
};

export const authenticateRefreshToken = async (token) => {
	const request = {
		token
	}

	const result = await axios.post("https://prescribe-message-backend.herokuapp.com/verify-refresh-token", request, header_);
	if (result.data) {
		const response = await result.data;
		return response.status;
	} else {
		return false;
	}
}

export const dateToTimestamp = (date, time) => {
	const dateArr = date.split("-");
	const timeArr = time.split(":");
	const date_ = new Date(
		parseInt(dateArr[2]),
		parseInt(dateArr[1]) - 1,
		parseInt(dateArr[0]),
		parseInt(timeArr[0]),
		parseInt(timeArr[1])
	);
	return date_.getTime() / 1000;
};

export function ModalTitle() {
    return (
        <div className="export-modal-title">Select Metrics to be exported</div>
    )
}

export const resetPassword = async (password) => {
    try {
        const currentUser = firebase.auth().currentUser;
        await currentUser.updatePassword(password);
        return true;
    } catch (error) {
        alert(error.message);
        return false;
    }
};
export function changeFavicon(count) {
	const faviconElem = document.querySelector('link[rel="icon"]');
	if (faviconElem) {
		const faviconFileName = count > 0 ? "/favicon-unread.png" : "/favicon.ico";
		faviconElem.setAttribute("href", faviconFileName);
	}
}
export function changeTitle(count) {
	const prefix = count > 0 ? `(${count}) ` : "";
	const defaultTitle = "Prescribe - CRM Dashboard";
	const titleElem = document.querySelector("title");
	if (titleElem) {
		titleElem.textContent = prefix + defaultTitle;
	}
}
export const sendTagAnalytics = (tag, type) => {
    if (tag.toLowerCase() === "important") {
        sendAmplitudeData(IMPORTANT_CHANGED, {
            type: "add" ? "marked" : "unmarked",
        });
    } else {
        sendAmplitudeData(TAGS_CHANGED, {
            type: type + "ed", //"added" or "removed"
            value: tag,
        });
    }
};
export const downloadExampleCSV = (columnStr) => {
	const params = columnStr !== "" ? columnStr.split("$") : [];
	const fields = [...params, "phone", "receiver_name"];
	const data = Array(3)
		.fill(0)
		.map(() => fields.map(() => Math.random()));
	const csv = unparse({ fields, data });
	downloadCSV(csv, "template-example.csv");
};
export function getDateAndTime() {
	const timeNow = dayjs().format("DD-MM-YYYY HH:MM");
	const [date, time] = timeNow.split(" ");
	return { date, time };
}

