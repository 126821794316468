import AnalyticsPage from './AnalyticsPage';
import Graph from "./Graph";
import '../css/reportAnalytics.css';
import {
    resultsWindow,
  } from "../utils/contants";
  
class ReportAnalytics extends AnalyticsPage {
    constructor(props) {
        super({ adminId: props.adminId, adminNum: props.adminNum });
        this.state = {
            ...this.state,
            filteredGraphData: {}
        }
    }

    filterData(param, dateString) {
        const refDate = new Date();
        const lastWeekStart = new Date(refDate.getFullYear(), refDate.getMonth(), refDate.getDate() - refDate.getDay() - 7);
        const lastWeekEnd = new Date(refDate.getFullYear(), refDate.getMonth(), refDate.getDate() - refDate.getDay());
        const dateArr = dateString.split("-");
		if (dateArr.length < 3) return false;
        const day = parseInt(dateArr[0]);
		const month = parseInt(dateArr[1]);
		const year = parseInt(dateArr[2]);
        const cmpDate = new Date(year, month - 1, day);
        if(param === resultsWindow.TODAY.value) {
            return (day === refDate.getDate() && month - 1 === refDate.getMonth() && year === refDate.getFullYear());
        } else if(param === resultsWindow.LAST_WEEK.value) {
            return (cmpDate.getTime() >= lastWeekStart.getTime() && cmpDate.getTime() <= lastWeekEnd.getTime());
        } else if(param === resultsWindow.LAST_MONTH.value) {
            return (month - 1 === (refDate.getMonth() + 11) % 12 && year === refDate.getFullYear());
        } else if(param === resultsWindow.LAST_YEAR.value) {
            return (year === refDate.getFullYear() - 1);
        } else if(param === resultsWindow.FOREVER.value) {
            return true;
        } else {
           return (cmpDate.getTime() >= this.props.startDate.getTime() && cmpDate.getTime() <= this.props.endDate.getTime());
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        return ((nextProps.filterParam !== this.props.filterParam ||
                nextProps.startDate !== this.props.startDate || 
                nextProps.endDate !== this.props.endDate
        ) || ((!this.state.graphData.datasets && nextState.graphData.datasets) || (this.state.filteredGraphData !== nextState.filteredGraphData)))
    }

    componentDidUpdate(prevProps, prevState) {
        if(!prevState.graphData.datasets && this.state.graphData.datasets) {
            this.setState({
                filteredGraphData: {...this.state.graphData},
            });
            this.props.setData({...this.state.graphData});
        }
        
        if(prevProps.filterParam !== this.props.filterParam || prevProps.startDate !== this.props.startDate || prevProps.endDate !== this.props.endDate) {
            let receivedCount = 0;
            let sentCount = 0;
            let autoReplyCount = 0;
            let adminMessagesCount = 0;
            let templateMessageCount = 0;
            let sessionMessages = 0;
            let uniqueClientCount = 0;

            if(this.state.graphData.datasets) {
                const datasets = this.state.graphData.datasets;
                const filteredData = {
                    labels: [],
                    datasets: [
                        {
                            label: "Admin Messages",
                            data: [],
                            fill: false,
                            backgroundColor: "rgb(255, 99, 132)",
                            borderColor: "rgb(255, 99, 132)",
                        },
                        {
                            label: "Auto Reply",
                            data: [],
                            fill: false,
                            backgroundColor: "rgb(75, 192, 192)",
                            borderColor: "rgb(75, 192, 192)",
                        },
                        {
                            label: "Received Messages",
                            data: [],
                            fill: false,
                            backgroundColor: "rgb(99, 255, 132)",
                            borderColor: "rgb(99, 255, 132)",
                        },
                        {
                            label: "Sent Messages",
                            data: [],
                            fill: false,
                            backgroundColor: "rgb(175, 99, 255)",
                            borderColor: "rgb(175, 99, 255)",
                        },
                        {
                            label: "Session Messages",
                            data: [],
                            fill: false,
                            backgroundColor: "rgb(75, 99, 132)",
                            borderColor: "rgb(75, 99, 132)",
                        },
                        {
                            label: "Template Messages",
                            data: [],
                            fill: false,
                            backgroundColor: "rgb(255, 193, 22)",
                            borderColor: "rgb(255, 193, 22)",
                        },
                        {
                            label: "Unique Clients",
                            data: [],
                            fill: false,
                            backgroundColor: "rgb(255, 75, 22)",
                            borderColor: "rgb(255, 75, 22)",
                        },
                    ],
                };
                this.state.graphData.labels.forEach((date, i) => {
                    if(this.filterData(this.props.filterParam, date)) {
                        filteredData.labels.push(date);
                        datasets.forEach((item, index) => {
                            filteredData.datasets[index].data.push(item.data[i]);
                        });
                        adminMessagesCount += datasets[0].data[i];
                        autoReplyCount += datasets[1].data[i];
                        receivedCount += datasets[2].data[i];
                        sentCount += datasets[3].data[i];
                        sessionMessages += datasets[4].data[i];
                        templateMessageCount += datasets[5].data[i];
                        uniqueClientCount += datasets[6].data[i];
                    }
                });
                this.setState({
                    filteredGraphData: filteredData,
                    receivedCount,
                    sentCount,
                    autoReplyCount,
                    adminMessagesCount,
                    sessionMessages,
                    templateMessageCount,
                    uniqueClientCount
                });
                console.log(uniqueClientCount);
                this.props.setData(filteredData);
            }
        }
    }

    render() {
		return (
			<>
				<div className="report-stats-container">
					<div className="report-graph-container">
						<div className="analytics_graph">
							<Graph graphData={this.state.filteredGraphData} />
						</div>
					</div>
                    <div className="report-paper-container">
						<div className="messages_recieved">
							<div>Total Messages Received</div>
							<div>
								<b>{this.state.receivedCount}</b>
							</div>
						</div>
						<div className="total_session_messages_paper">
							<div>Total Session Messages</div>
							<b>{this.state.sessionMessages}</b>
						</div>
						<div className="messages_sent">
							<div>Total Messages Sent</div>
							<div>
								<b>{this.state.sentCount}</b>
							</div>
						</div>
						<div className="session_autoreplies_paper">
							<div>Total Auto Replies</div>
							<div>
								<b>{this.state.autoReplyCount}</b>
							</div>
						</div>
						<div className="template_messages">
							<div>Total Template messages</div>
							<div>
								<b>{this.state.templateMessageCount}</b>
							</div>
						</div>
                        {/* <div className="unique_clients">
							<div>Unique Clients</div>
							<div>
								<b>{this.state.uniqueClientCount}</b>
							</div>
						</div> */}
					</div>
				</div>
			</>
		);
	}
}

export default ReportAnalytics;