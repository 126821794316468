import React from "react";
import { Line } from "react-chartjs-2";
import {
    options
  } from "../utils/contants";
  

const Graph = ({ graphData }) => <Line data={graphData} options={options} />;

export default Graph;
