import React, { useEffect, useState } from "react";
import { parse,} from "papaparse";
import TemplateCard from "./TemplateCard";
import axios from "axios";
import { Upload, Button, Typography, Space, Modal, Row, Col, Empty, Spin } from "antd";
import { FileAddOutlined, SendOutlined, UploadOutlined } from "@ant-design/icons";
import S3FileUpload from "react-s3";
import { ATTACHMENT_SENT } from "../utils/amplitudeEvents";
import { sendAmplitudeData } from "../utils/amplitudeFunctions";

import {
	db,
	adminNum,
	propValues,
	FETCHING_TEMPLATES,
	SENDING_MESSAGES,
	header_,
  } from "../utils/contants";
import {
	downloadExampleCSV
  } from "../utils/functions";
  

export default function TemplatePage(props) {
	const [csvData, setCsvData] = useState([]);
	const [fileList, setFileList] = useState([]);
	const [allTemplates, setTemplates] = useState([]);
	const [selectedTemplateData, setSelectedTemplateData] = useState({});
	const [loading, setLoading] = useState(FETCHING_TEMPLATES);
	const [attachment, setAttachment] = useState(null);
	const [showModal, setShowModal] = useState(false);
	const [isSending, setIsSending] = useState(false);
	const [uploadUrl, setUploadUrl] = useState("");

	useEffect(() => {
		db.ref(`templateMessages/${adminNum}`).once("value", (snapshot) => {
			if (snapshot.exists()) {
				const templateList = snapshot.val().filter((entry) => entry);
				setTemplates(templateList);
			}
			setLoading("");
		});
	}, []);

	const sendMessage = async () => {
		if (csvData.length === 0) return;
		if (Object.keys(selectedTemplateData).length === 0) return;

		setLoading(SENDING_MESSAGES);
		const request = {
			data: csvData,
			message: selectedTemplateData.boilerPlate,
			params: selectedTemplateData.params,
			sender: adminNum,
			template_id: selectedTemplateData.template_id,
			type: selectedTemplateData.type,
			media_upload_url: uploadUrl,
			media_file_name: attachment?.name
		};
		try {
			const res = await axios.post(
				"https://prescribe-message-backend.herokuapp.com/send-template-message-excel",
				request,
				header_
			);
			if (res.data && res.data.success) {
				Modal.success({
					title: "Success!",
					content: "Your messages have been queued and will reach your selected contacts.",
				});
			}
		} catch (error) {
			Modal.error({
				title: "Oops!",
				content: "An error occured:" + error.message,
			});
		} finally {
			setLoading("");
		}
	};

	const uploadProps = {
		onRemove: (file) => {
			setFileList([]);
		},
		beforeUpload: (file) => {
			setFileList((prevFileList) => [file]);
			return false;
		},
		onChange: (info) => {
			const { file } = info;

			if (file.status !== "removed") {
				parse(file, {
					header: true,
					transformHeader: (header) => header.toLowerCase().replace(/\W/g, "_"),
					dynamicTyping: true,
					skipEmptyLines: true,
					complete: ({ data }) => {
						setCsvData(data);
					},
				});
			} else {
				setCsvData({});
			}
		},

		fileList,
		accept: ".csv",
		disabled: !selectedTemplateData.template_id,
	};

	const uploadMedia = () => {
		setShowModal(true);
	}

	const handleMediaUpload = () => {
		setIsSending(true);
		const config = {
			bucketName: "crmdashboardfiles",
			dirName: `${adminNum}/${propValues[selectedTemplateData.type].dirPath}`,
			region: "ap-south-1",
			accessKeyId: process.env.REACT_APP_ACCESS_KEY,
			secretAccessKey: process.env.REACT_APP_API_KEY,
		};

		sendAmplitudeData(ATTACHMENT_SENT, { type: attachment.type, size: attachment.size });
		S3FileUpload.uploadFile(attachment, config)
			.then((data) => {
				setUploadUrl(data.location);
				setShowModal(false);
			})
			.catch((err) => {
				alert(err.message);
			});
	}

	const handleMediaChange = (e) => {
		if (e.target.files[0]) {
			const _attachment = e.target.files[0];
			setAttachment(_attachment);
		}
	};

	const hideModal = () => {
		setShowModal(false);
	}

	return (
		<div style={{ padding: "1em" }}>
			<div style={{ padding: "1em" }}>
				<Modal
					title={`Upload ${selectedTemplateData.type}`}
					visible={showModal}
					onOk={handleMediaUpload}
					okButtonProps={{
						disabled: attachment === null,
						loading: isSending,
					}}
					onCancel={hideModal}
					okText="Upload">
					{(selectedTemplateData.type !== undefined && selectedTemplateData.type !== 'text') && <Typography>*Only {propValues[selectedTemplateData.type].fileTypes} file type(s) are supported.</Typography>}
					<br />
					{(selectedTemplateData.type !== undefined && selectedTemplateData.type !== 'text') && <input type="file" onChange={handleMediaChange} accept={propValues[selectedTemplateData.type].accept} />}
				</Modal>
				<Typography.Title level={3}>Select a template to share as message</Typography.Title>
				<Space align="start" size="large">
					<Upload {...uploadProps}>
						<Button size="large" type="primary" disabled={!selectedTemplateData.template_id}>
							Select File <FileAddOutlined />
						</Button>
					</Upload>
					<Button
						size="large"
						type="primary"
						loading={loading === SENDING_MESSAGES}
						disabled={!selectedTemplateData.template_id || fileList.length === 0 || (selectedTemplateData.type !== 'text' && uploadUrl === '')}
						onClick={sendMessage}>
						Send Bulk Message <SendOutlined />
					</Button>
					{(selectedTemplateData.type !== undefined && selectedTemplateData.type !== 'text') && <Button
						size="large"
						type="primary"
						// disabled={!selectedTemplateData.template_id || fileList.length === 0}
						onClick={uploadMedia}>
						Select Media <UploadOutlined />
					</Button>}
				</Space>
			</div>
			<div style={{ padding: "1em" }}>
				<Typography.Title level={3}>Templates</Typography.Title>
				<Row gutter={24}>
					{allTemplates.length > 0 ? (
						allTemplates.map((data) => (
							<Col xs={24} md={18} lg={12} key={data.template_id}>
								<TemplateCard
									data={data}
									isSelected={data.template_id === selectedTemplateData.template_id}
									setSelectedTemplateData={setSelectedTemplateData}
									downloadExampleCSV={() => downloadExampleCSV(data.params)}
								/>
							</Col>
						))
					) : loading === FETCHING_TEMPLATES ? (
						<Spin size="large" />
					) : (
						<Empty description="No Templates in your account" />
					)}
				</Row>
			</div>
		</div>
	);
}
