import React, { useState } from 'react';
import { Modal } from 'antd';
import dayjs from 'dayjs';
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs';
import generatePicker from 'antd/es/date-picker/generatePicker';
import '../css/datePickerModal.css';

const DatePicker = generatePicker(dayjsGenerateConfig);

export default function DatePickerModal({ isModalVisible, setIsModalVisible, handleConfirm, client }) {
    const [startDate, setStartDate] = useState(dayjs());
    const [endDate, setEndDate] = useState(dayjs());

    const handleDateRangeSelect = (startDate, endDate) => {
        handleConfirm(startDate.toDate(), endDate.toDate(), client);
        setIsModalVisible(false);
    }

    const handleModalClose = () => {
        setIsModalVisible(false);
    }

    const handleStartDateChange = (date) => {
        setStartDate(date);
    }

    const handleEndDateChange = (date) => {
        setEndDate(date);
    }

    return (
        <Modal
            title="Please select a date range"
            visible={isModalVisible}
            onOk={handleDateRangeSelect.bind(null, startDate, endDate)}
            onCancel={handleModalClose}
        >
            <div className="modal-container">
                <div className="picker-container">
                    <span className="picker-label">Start Date:</span>
                    <DatePicker value={startDate} onChange={handleStartDateChange} />
                </div>
                <div className="picker-container">
                    <span className="picker-label">End Date:</span>
                    <DatePicker value={endDate} onChange={handleEndDateChange} />
                </div>
            </div>
        </Modal>
    )
}