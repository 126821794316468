import React, { useEffect } from "react";
import "antd/dist/antd.css"
import "./css/App.css";
import { BrowserRouter as Router, Redirect } from "react-router-dom";
import { Route, Switch } from "react-router-dom";
import { PrivateRoute, PublicRoute } from "./components/Protected";
import LogOut from "./components/Logout";
import Login from "./components/Login";
import Verification from "./components/Verification";
import ResetPassword from "./components/ResetPassword";
import MainDashboard from "./components/MainDashboard";
import firebase from "./Firebase";


const addTokenToDB = async (token) => {
	const phoneNum = localStorage.getItem("phoneNum");
	if (phoneNum) {
		const adminSnapshot = await firebase.database().ref('Users').orderByChild("userPhone").equalTo(phoneNum).once("value");

		if (adminSnapshot.exists()) {
			const adminId = Object.values(adminSnapshot.val())[0].id;
			const adminUid = localStorage.getItem("adminUID");
			firebase.database().ref(`pwa_token_list/${adminId}/${adminUid}/${token}`).set(true);
		}
	}
}

export default function App() {

	useEffect(() => {
		const messaging = firebase.messaging();
		const phoneNum = localStorage.getItem("phoneNum");
		if (Notification.permission !== 'granted' && phoneNum) {
			Notification.requestPermission().then((permission) => {
				if (permission === "granted") {
					messaging.getToken().then((data) => {
						localStorage.setItem("notificationToken", data);
						addTokenToDB(data);
					});
				}
			})
		}
	}, [])
	
	return (
		<Router>
			<Switch>
				<Route path="/" exact component={() => <Redirect to="/dashboard/conversations" />} />
				<PublicRoute path="/login" exact component={Login} />
				<PublicRoute path="/verification" component={Verification} />
				<PublicRoute path="/reset" component={ResetPassword} />
				<PrivateRoute path="/dashboard" component={MainDashboard} />
				<Route path="/logout" exact component={LogOut} />
			</Switch>
		</Router>
	);
}
