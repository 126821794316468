import React, { useState } from 'react';
import { Modal, Checkbox } from 'antd';
import { unparse } from 'papaparse';
import '../css/reportExportModal.css';
import {
	exportOptions,
    exportOptionsList,
  } from "../utils/contants";
import downloadCSV from '../utils/downloadCSV';
import {
	ModalTitle,
  } from "../utils/functions";

export default function ReportExportModal({ isModalVisible, setIsModalVisible, data }) {
    const [checkAll, setCheckAll] = useState(false);
    const [checkedList, setCheckedList] = useState([]);

    const handleChecklistChange = (event) => {
        const selectedValue = event.target.value;
        if(checkedList.includes(selectedValue)) {
            setCheckedList(checkedList.filter(value => value !== selectedValue));
            if(checkAll) setCheckAll(false);
        } else {
            const newList = [...checkedList];
            newList.push(selectedValue);
            setCheckedList(newList);
            if(newList.length === exportOptionsList.length) setCheckAll(true);
        }
    }

    const handleCheckAll = () => {
        setCheckAll(!checkAll);
        if(!checkAll) setCheckedList(exportOptionsList.map(option => option.value));
        else setCheckedList([]);
    }

    const handleExport = () => {
        const fields = ['date', ...checkedList];
        const exportCSVData = [];
        const exportDatasetIndices = exportOptionsList.filter(option => checkedList.includes(option.value)).map(option => option.index);
        const exportDatasets = data.datasets.filter((_, i) => exportDatasetIndices.includes(i));
        for(let i = 0; i < data.labels.length; ++i) {
            const csvRow = [];
            csvRow.push(data.labels[i]);
            exportDatasets.forEach(set => {csvRow.push(set.data[i])});
            exportCSVData.push(csvRow);
        }
        const csv = unparse({ fields, data: exportCSVData });
        downloadCSV(csv, 'analytics-export.csv');
    }

    const handleCancel = () => {
        setIsModalVisible(false);
        setCheckAll(false);
        setCheckedList([]);
    }
    
    return (
        <Modal
            visible={isModalVisible}
            title={<ModalTitle />}
            closable={false}
            onCancel={handleCancel}
            onOk={handleExport}
            okText="Export"
            okButtonProps={{ className: 'modal-export-btn' }}
            cancelButtonProps={{ className: 'modal-cancel-btn' }}
            width='588px'
        >
            <div className="export-modal-container">
                <div className="export-filters">
                    <div className="filter-item everything-checkbox">
                            <Checkbox checked={checkAll} onChange={handleCheckAll} value={exportOptions.EVERYTHING.value}>
                                {exportOptions.EVERYTHING.label}
                            </Checkbox>
                    </div>
                    <div>
                        <div className="filter-item">
                            <Checkbox onChange={handleChecklistChange} checked={checkedList.includes(exportOptions.ADMIN.value)} value={exportOptions.ADMIN.value}>
                                {exportOptions.ADMIN.label}
                            </Checkbox>
                        </div>
                        <div className="filter-item">
                            <Checkbox onChange={handleChecklistChange} checked={checkedList.includes(exportOptions.AUTO.value)} value={exportOptions.AUTO.value}>
                                {exportOptions.AUTO.label}
                            </Checkbox>
                        </div>
                        <div className="filter-item">
                            <Checkbox onChange={handleChecklistChange} checked={checkedList.includes(exportOptions.RECEIVED.value)} value={exportOptions.RECEIVED.value}>
                                {exportOptions.RECEIVED.label}
                            </Checkbox>
                        </div>
                        <div className="filter-item">
                            <Checkbox onChange={handleChecklistChange} checked={checkedList.includes(exportOptions.SENT.value)} value={exportOptions.SENT.value}>
                                {exportOptions.SENT.label}
                            </Checkbox>
                        </div>
                        <div className="filter-item">
                            <Checkbox onChange={handleChecklistChange} checked={checkedList.includes(exportOptions.SESSION.value)} value={exportOptions.SESSION.value}>
                                {exportOptions.SESSION.label}
                            </Checkbox>
                        </div>
                        <div className="filter-item">
                            <Checkbox onChange={handleChecklistChange} checked={checkedList.includes(exportOptions.TEMPLATE.value)} value={exportOptions.TEMPLATE.value}>
                                {exportOptions.TEMPLATE.label}
                            </Checkbox>
                        </div>
                        <div className="filter-item">
                            <Checkbox onChange={handleChecklistChange} checked={checkedList.includes(exportOptions.UNIQUE.value)} value={exportOptions.UNIQUE.value}>
                                {exportOptions.UNIQUE.label}
                            </Checkbox>
                        </div>
                    </div>
                </div>
                {/* <div>
                    <div className="export-comment-container">
                        <div className="export-comment-label">Comment</div>
                        <TextArea
                            className="export-comment-text"
                            placeholder="Type any comment that you may have"
                            rows={9}
                        />
                    </div>
                </div> */}
            </div>
        </Modal>
    )
}