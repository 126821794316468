import React, { useState, useEffect } from "react";
import { Button, Table } from "antd";
import firebase from "../Firebase.js";
import dayjs from "dayjs";

import downloadCSV from "../utils/downloadCSV";
import { unparse as parseToCSV } from "papaparse";
import { sendAmplitudeData } from "../utils/amplitudeFunctions.js";
import { CSV_DOWNLOADED } from "../utils/amplitudeEvents.js";
import {
	getContactKeysAndTimestamps,
  } from "../utils/functions";
  import {
	Users,
	
  } from "../utils/contants"
const formatDate = (tsInMs) => dayjs(tsInMs).format("DD/MM/YYYY hh:mm A");

const columns = [
	{
		dataIndex: "userDisplayName",
		title: "Full Name",
		sorter: ({ userDisplayName: aname }, { userDisplayName: bname }) => aname.localeCompare(bname),
	},
	{
		dataIndex: "userPhone",
		title: "Phone Number",
		type: "string",
	},
	{
		dataIndex: "timestamp",
		title: "Timestamp",
		sorter: ({ timestamp: tsa }, { timestamp: tsb }) => tsa - tsb,
		render: (timestamp) => formatDate(timestamp),
	},
];

async function getContact(id) {
	const currContactSnapshot = firebase.database().ref(`${Users}/${id}`);
	const snapshot = await currContactSnapshot.once("value");

	const contact = snapshot.exists() ? snapshot.val() : {};
	return contact;
}

const getAllContactsList = async (adminId) => {
	const contactKeysAndTimestampPairs = await getContactKeysAndTimestamps(adminId);
	const promises = contactKeysAndTimestampPairs.map((pair) => getContact(pair[0]));
	const results = await Promise.all(promises);
	const timestampObj = Object.fromEntries(contactKeysAndTimestampPairs);
	const contacts = results
		.map(({ id, userDisplayName, userPhone }) => {
			return {
				id,
				userDisplayName,
				userPhone,
				timestamp: timestampObj[id] * 1000,
			};
		})
		.filter((contact) => contact.id && contact.timestamp);
	return contacts;
};

const exportCSV = (data) => {
	const fields = ["Name", "Phone", "Timestamp"];
	const csvData = parseToCSV({
		fields,
		data: data.map(({ id, ...row }) => Object.values({ ...row, timestamp: formatDate(row.timestamp) })),
	});
	downloadCSV(csvData, `Contacts - ${dayjs().format("DD-MM HHMM")}.csv`);
	sendAmplitudeData(CSV_DOWNLOADED, { length: data.length });
};

export default function AllContacts({ adminId }) {
	const [rows, setRows] = useState([]);
	const [selectedContacts, setSelectedContacts] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		getAllContactsList(adminId).then((contacts) => {
			setRows(contacts);
			setLoading(false);
		});
	}, [adminId]);

	const handleSelectionChange = (_, selectedContacts) => {
		setSelectedContacts(selectedContacts);
	};

	return (
		<div
			style={{
				height: "calc(100vh - 64px)",
				width: "calc(100% - 1px)", // hack to stop flickering
			}}>
			<Table
				loading={loading}
				rowKey={({ id }) => id}
				sticky={true}
				rowSelection={{
					onChange: handleSelectionChange,
					selectedRowKeys: selectedContacts.map(({ id }) => id),
				}}
				pagination={{
					defaultPageSize: 12,
					style: { paddingRight: "250px" },
				}}
				columns={columns}
				dataSource={rows}
			/>
			<Button
				type="primary"
				style={{ position: "absolute", bottom: "10px", marginLeft: "10px" }}
				onClick={() => {
					if (selectedContacts.length === 0) {
						exportCSV(rows);
					} else {
						exportCSV(selectedContacts);
					}
				}}>
				Export {selectedContacts.length === 0 ? "all" : "selected"} as CSV
			</Button>
		</div>
	);
}
