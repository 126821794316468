import React from "react";
import { Skeleton, Card } from "antd";

export default function SkeletonCard() {
  return (
    <Card className="skele_card">
      <Skeleton loading active></Skeleton>
    </Card>
  );
}
