import React, { useContext } from "react";
import { Button, Dropdown, Menu } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { algoliaIndex } from "../Algolia.js";
import { sendAmplitudeData } from "../utils/amplitudeFunctions";
import { ASSIGNED_TO } from "../utils/amplitudeEvents";
import { myContext } from "./myContext";
import {
	db,
	v2_agentWiseChats,
	v2_assignedChats,
	v2_userChats,

  } from "../utils/contants";
import {
	dateToTimestamp,
  } from "../utils/functions";

export default function AssignToDropdown(props) {
	// const [attendantId, setAttendantId] = useState("");
	const { adminList, adminId } = useContext(myContext);

	// useEffect(() => {
	// 	const ref = db.ref(`Users/${props.contact.id}`);
	// 	ref.on("value", (snapshot) => {
	// 		const { attendedById } = snapshot.val();
	// 		setAttendantId(attendedById);
	// 	});
	// 	return () => {
	// 		ref.off();
	// 	};
	// }, [props.contact.id]);

	const assignUserToAdmin = (adminName, adminID_) => {
		var ts_ = dateToTimestamp(props.lastMessageDate, props.lastMessageTime);
		var obj = props.chatObj;
		obj['attendedById'] = adminID_;
		obj['attendedBy'] = adminName;
		obj['auto_reply'] = 'false';
		obj['auto_reply_ts'] = ts_;
		db.ref(`${v2_agentWiseChats}/${props.attendedById}/${props.contact.id}`).set(null);
		db.ref(`${v2_agentWiseChats}/${adminID_}/${props.contact.id}`).set(obj);
		db.ref(`${v2_assignedChats}/${adminId}/${props.contact.id}`).set(obj);
		db.ref(`${v2_userChats}/${adminId}/${props.contact.id}`).set(obj);
		algoliaIndex.partialUpdateObject({
			'objectID': props.id,
			'attendedById': adminID_
		});
		// db.ref(`Users/${props.contact.id}`).update({
		// 	attendedBy: adminName,
		// 	attendedById: adminID_,
		// });
	};

	const menu = (
		<Menu>
			{adminList.map(({ name, uid }) => (
				<Menu.Item
					key={uid}
					onClick={() => {
						sendAmplitudeData(ASSIGNED_TO, { name });
						assignUserToAdmin(name, uid);
					}}>
					{name}
				</Menu.Item>
			))}
		</Menu>
	);
	return (
		<Dropdown overlay={menu}>
			<Button type="primary">
				Assign To <DownOutlined />
			</Button>
		</Dropdown>
	);
}
