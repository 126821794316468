import { Modal, Typography, Input } from "antd";
import axios from "axios";
import React, { useState } from "react";
import S3FileUpload from "react-s3";
import { ATTACHMENT_SENT } from "../utils/amplitudeEvents";
import { sendAmplitudeData } from "../utils/amplitudeFunctions";
import {
	carrier,
	header_,
	propValues,
  } from "../utils/contants";
import {
	getDateAndTime,
  } from "../utils/functions";

export default function UploadModal(props) {
	const [attachment, setAttachment] = useState(null);
	const [caption, setCaption] = useState("");
	const [isSending, setIsSending] = useState(false);

	const handleOk = (e) => {
		setIsSending(true);

		const config = {
			bucketName: "crmdashboardfiles",
			dirName: `${props.adminNum}/${propValues[props.type].dirPath}`,
			region: "ap-south-1",
			accessKeyId: process.env.REACT_APP_ACCESS_KEY,
			secretAccessKey: process.env.REACT_APP_API_KEY,
		};

		sendAmplitudeData(ATTACHMENT_SENT, { type: attachment.type, size: attachment.size });
		S3FileUpload.uploadFile(attachment, config)
			.then((data) => {
				sendImage(data.location);
			})
			.catch((err) => {
				alert(err.message);
			});
	};

	const sendImage = (url_) => {
		const { date, time } = getDateAndTime();
		const request = {
			sender: props.adminNum,
			receiver: props.contact.userPhone,
			type: props.type,
			date,
			time,
			carrier,
			payload: {
				message: url_,
				caption,
				admin_id: props.adminId,
				client_id: props.contact.id,
				chat_id: props.chatId,
				agent_id: props.agentId,
				client_name: props.contact.userDisplayName,
			},
		};

		axios.post("https://prescribe-message-backend.herokuapp.com/admin-message-v2", request, header_).finally(() => {
			props.hideModal();
			setIsSending(false);
		});
	};

	function handleOnChange(e) {
		e.preventDefault();
		setCaption(e.target.value);
	}

	const handleChange = (e) => {
		if (e.target.files[0]) {
			const _attachment = e.target.files[0];
			setAttachment(_attachment);
		}
	};
	return (
		<Modal
			title={`Upload ${props.type}`}
			visible={props.isVisible}
			onOk={handleOk}
			okButtonProps={{
				disabled: attachment === null,
				loading: isSending,
			}}
			onCancel={props.hideModal}
			okText="Upload">
			<Typography>*Only {propValues[props.type].fileTypes} file type(s) are supported.</Typography>
			<br />
			<Input autoComplete="off" onChange={handleOnChange} style={{ marginBottom: "2em" }} placeholder="Caption" />
			<br />
			<input type="file" onChange={handleChange} accept={propValues[props.type].accept} />
		</Modal>
	);
}
