import { Checkbox, Col, Row, Input, Select, Radio } from "antd";
import React, { Component } from "react";
import firebase from "../Firebase.js";
import { getIndex } from "../Algolia.js";
import BlankWindow from "./BlankWindow.jsx";
import ChatWindowV2 from "./ChatWindowV2.jsx";
import FilterBarCompo from "./FilterBarCompo.jsx";
import { sendAmplitudeData } from "../utils/amplitudeFunctions.js";
import { FILTER_APPLIED, QUERY_SEARCHED } from "../utils/amplitudeEvents";
import { myContext } from "./myContext.js";
import KeyWordChatWindow from "./KeywordChatWindow";
import {
	adminUID ,
	adminNum,
	Users,
  } from "../utils/contants";

export class InfiniteList extends Component {
	constructor(props) {
		super(props);
		this.currStart = 0;
		this.windowLimit = 30;
		this.currEnd = this.currStart + this.windowLimit;
		this.chatKeys = {};
		this.contactKeys = [];
		this.downScrollLimit = 0.85;
		this.upScrollLimit = 0.0;
		this.fraction = 0.0;
		this.state = {
			chatWindows: [],
			keywordWindows: [],
			menuId: props.menuId,
			dbPath: props.dbPath,
			loading: false,
			showAssistanceRequired: false,
			showUnread: false,
			filterOn: false,
			searchOn: false,
			searchQuery: "",
			selectedTags: [],
			searchMode: "1",
			listMode: "1",
		};
		this.chatWindowArr = [];
	}

	componentDidMount() {
		var path = "";
		if (this.state.menuId === "agentWise") path = this.state.dbPath + adminUID;
		else path = this.state.dbPath + this.context.adminId;
		this.addChatsListener(path, this.state.menuId);
	}

	filterByTags = (selectedTags) => {
		this.setState({ selectedTags }, () => {
			this.applyFilter(selectedTags);
			sendAmplitudeData(FILTER_APPLIED, {
				type: "tags",
				selectedTags,
			});
		});
	};

	applyFilter = (tags) => {
		this.chatKeys = {};
		this.contactKeys = [];
		this.currStart = 0;
		this.currEnd = this.currStart + this.windowLimit;
		var tagQuery = "";
		var delim = "";
		for (const tag in tags) {
			tagQuery += delim;
			// tagQuery += 'tags:' + '"' + tags[tag] + '"';
			tagQuery += `tags:"${tags[tag]}"`
			delim = " OR ";
		}

		if (this.props.menuId === "agentWise") {
			if (tagQuery !== "") tagQuery += " AND ";
			tagQuery += `attendedById:"${adminUID}"`;
		}

		if (this.state.showUnread) {
			if (tagQuery !== "") tagQuery += " AND ";
			tagQuery += `unread:true`;
		}

		if (this.state.showAssistanceRequired) {
			if (tagQuery !== "") tagQuery += " AND ";
			tagQuery += `assistanceRequired:true`;
		}

		const algoliaIndex_ = getIndex(localStorage.getItem('phoneNum'));
		algoliaIndex_
			.search("", {
				filters: tagQuery,
				attributesToRetrieve: ["objectID", "name", "phone", "contactID", "timestamp"],
				hitsPerPage: 100,
			})
			.then(({ hits }) => {
				for (const hit in hits) {
					const contactID = hits[hit].contactID;
					const chatID = hits[hit].objectID;
					const ts = hits[hit].timestamp;
					if (contactID === undefined) {
						continue;
					}
					this.contactKeys.push(contactID);
					this.chatKeys[contactID] = {
						chatId: chatID,
						timestamp: ts,
					};
					// const newWindow = this.getChatWindow(contactID, true);
					// chatWindowArr_.push(newWindow);
				}
				this.setState(
					(prev) => ({
						...prev,
						filterOn: true,
						searchMode: prev.searchMode,
						listMode: "1",
					}),
					() => {
						this.addChatWindows(this.currStart, this.currEnd);
					}
				);
			});
	};

	// onEnter = (value, event) => {
	// 	this.performSearch(value);
	// };

	// onSearchClick = (value) => {
	// 	this.performSearch(value);
	// };

	changeSearchMode = (val) => {
		this.setState({
			searchMode: val,
		});
	};

	performAlgoliaSearch = (value) => {
		var searchOn = false;
		var searchMode = this.state.searchMode;
		this.chatKeys = {};
		this.contactKeys = [];
		this.currStart = 0;
		this.currEnd = this.currStart + this.windowLimit;
		const filterQuery = this.props.menuId === "agentWise" ? `attendedById:${adminUID}`: "";
		if (value !== "") {
			searchOn = true;
		} else {
			searchMode = "1";
		}
		const algoliaIndex_ = getIndex(adminNum);
		if (searchMode === "1") {
			algoliaIndex_
				.search(value, {
					filters: filterQuery,
					restrictSearchableAttributes: ["name"],
					attributesToRetrieve: ["objectID", "name", "phone", "contactID", "timestamp", "attendedById"],
					hitsPerPage: 100,
				})
				.then(({ hits }) => {
					for (const hit in hits) {
						const contactID = hits[hit].contactID;
						const chatID = hits[hit].objectID;
						const ts = hits[hit].timestamp;
						if (contactID === undefined) continue;
						this.contactKeys.push(contactID);
						this.chatKeys[contactID] = {
							chatId: chatID,
							timestamp: ts,
						};
						// const newWindow = this.getChatWindow(contactID, true);
						// chatWindowArr_.push(newWindow);
					}
					this.setState(
						(prev) => ({
							...prev,
							searchOn: searchOn,
							searchMode: prev.searchMode,
							listMode: "1",
						}),
						() => {
							this.addChatWindows(this.currStart, this.currEnd);
						}
					);
				});
		} else if (searchMode === "2") {
			algoliaIndex_
				.search(value, {
					filters: filterQuery,
					restrictSearchableAttributes: ["messages"],
					attributesToRetrieve: ["objectID", "name", "phone", "contactID", "timestamp", "attendedById", "messagesDateTime"],
					hitsPerPage: 100,
				})
				.then(({ hits }) => {
					for (const hit in hits) {
						const name = hits[hit].name;
						const contactId = hits[hit].contactID;
						const chatId = hits[hit].objectID;
						const highlightResults = hits[hit]._highlightResult;
						const messages = highlightResults.messages;
						const messageDateTimes = hits[hit].messagesDateTime;
						if (contactId === undefined) continue;
						let messageProp = "";
						let messageIndex = -1;
						let messageDateTime = "";
						for (let i = messages.length - 1; i >= 0; i--) {
							if (messages[i].matchLevel === "full") {
								messageProp = messages[i].value;
								messageDateTime = messageDateTimes !== undefined ? messageDateTimes[i] : "";
								messageIndex = messages.length - i - 1;
								break;
							}
						}
						this.contactKeys.push(contactId);
						this.chatKeys[contactId] = {
							name: name,
							chatId: chatId,
							messageProp: messageProp,
							messageDateTime: messageDateTime,
							messageIndex: messageIndex,
						};
						// const newWindow = (
						//     <KeyWordChatWindow
						//         key={`${contactId}|${chatId}`}
						//         name={name}
						//         message={messageProp}
						//         contactId={contactId}
						//         chatId={chatId}
						//         messageIndex={messageIndex}
						//         changeDashboardState={this.props.changeDashboardState} />
						// );
						// chatWindowArr_.push(newWindow);
					}
					this.setState(
						{
							searchOn: searchOn,
							listMode: "2",
						},
						() => {
							this.addChatWindows(this.currStart, this.currEnd);
						}
					);
				});
		} else if (searchMode === "3") {
			algoliaIndex_
				.search(value, {
					filters: filterQuery,
					restrictSearchableAttributes: ["phone"],
					attributesToRetrieve: ["objectID", "name", "phone", "contactID", "timestamp"],
					hitsPerPage: 100,
				})
				.then(({ hits }) => {
					for (const hit in hits) {
						const contactID = hits[hit].contactID;
						const chatID = hits[hit].objectID;
						const ts = hits[hit].timestamp;
						if (contactID === undefined) continue;
						this.contactKeys.push(contactID);
						this.chatKeys[contactID] = {
							chatId: chatID,
							timestamp: ts,
						};
						// const newWindow = this.getChatWindow(contactID, true);
						// chatWindowArr_.push(newWindow);
					}
					this.setState(
						(prev) => ({
							...prev,
							searchOn: searchOn,
							searchMode: prev.searchMode,
							listMode: "3",
						}),
						() => {
							this.addChatWindows(this.currStart, this.currEnd);
						}
					);
				});
		}
		sendAmplitudeData(QUERY_SEARCHED, {
			type: /^\d+$/g.test(value) ? "number" : "name", // todo: doesn't account for country codes
			query: value,
		});
	};

	handleRadioGroupChange = (e) => {
		const selectedOption = e.target.value;
		if (selectedOption === 'all') {
			this.applyFilter([]);
		} else {
			this.applyFilter([selectedOption]);
		}
	}

	addChatsListener = (path, menuId) => {
		firebase
			.database()
			.ref(path)
			.orderByChild("neg_ts")
			.limitToFirst(200)
			.on("value", (snapshot) => {
				var i = 0;
				var shouldUpdate = true;
				if (menuId === "agentWise") {
					this.contactKeys = [];
					this.chatKeys = {};
				}
				snapshot.forEach((childSnapshot) => {
					if (i === 0) {
						const currKey = childSnapshot.key;
						// list has not changed
						if (menuId !== "agentWise" && this.contactKeys.length > 0 && currKey === this.contactKeys[0]) {
							shouldUpdate = false;
						} else {
							this.contactKeys = [];
							this.chatKeys = {};
						}
					}
					if (shouldUpdate) {
						this.contactKeys.push(childSnapshot.key);
						this.chatKeys[childSnapshot.key] = childSnapshot.val();
					} else {
						this.chatKeys[childSnapshot.key] = childSnapshot.val();
					}
					i++;
				});
				if (shouldUpdate && !this.state.filterOn && !this.state.searchOn) {
					this.addChatWindows(this.currStart, Math.min(this.currEnd, this.contactKeys.length));
				}
			});
	};

	handleUnreadChange = () => {
		this.setState(
			(prevState) => ({
				showUnread: !prevState.showUnread,
			}),
			() => {
				if (!this.state.showUnread) {
					this.currStart = 0;
					this.currEnd = this.currStart + this.windowLimit;
				}
				this.applyFilter(this.state.selectedTags);
				// this.addChatWindows(this.currStart, this.currEnd);
				// sendAmplitudeData(FILTER_APPLIED, {
				// 	type: "unread",
				// });
			}
		);
	};

	handleAssistanceRequiredChange = () => {
		this.setState(
			(prevState) => ({
				showAssistanceRequired: !prevState.showAssistanceRequired,
			}),
			() => {
				if (!this.state.showAssistanceRequired) {
					this.currStart = 0;
					this.currEnd = this.currStart + this.windowLimit;
				}
				this.applyFilter(this.state.selectedTags);
				// this.addChatWindows(this.currStart, this.currEnd);
				// sendAmplitudeData(FILTER_APPLIED, {
				// 	type: "assistanceRequired",
				// });
			}
		);
	};

	addChatWindows = (start, end) => {
		// console.log(start, end);
		var chatWindowArr_ = [];

		for (var i = 0; i < start; i++) {
			const newWindow = this.getBlankWindow();
			if (i > chatWindowArr_.length) chatWindowArr_.push(newWindow);
			else chatWindowArr_[i] = newWindow;
		}

		i = start;
		var counter = start;
		// console.log(i, end, counter, this.contactKeys.length);
		while (i < end && counter < this.contactKeys.length) {
			const contactId = this.contactKeys[counter];
			counter++;
			// if (
			// 	this.state.showAssistanceRequired &&
			// 	(this.chatKeys[contactId] === undefined ||
			// 		this.chatKeys[contactId].callback === undefined ||
			// 		!this.chatKeys[contactId].callback)
			// ) {
			// 	continue;
			// } else if (
			// 	this.state.showUnread &&
			// 	(this.chatKeys[contactId] === undefined ||
			// 		this.chatKeys[contactId].unread === undefined ||
			// 		this.chatKeys[contactId].unread === 0)
			// ) {
			// 	continue;
			// }
			var newWindow;
			if (this.state.listMode !== "2") {
				newWindow = this.getChatWindow(contactId, true);
			} else {
				newWindow = this.getKeywordWindow(contactId);
			}
			chatWindowArr_.push(newWindow);
			i++;
		}
		// if (!this.state.showAssistanceRequired && !this.state.showUnread) {
		// 	// console.log('Adding end windows...');
		// 	for (i = end; i < chatWindowArr_.length; i++) {
		// 		const newWindow = this.getBlankWindow();
		// 		chatWindowArr_[i] = newWindow;
		// 	}
		// } else {
		// 	this.currEnd = chatWindowArr_.length;
		// 	this.fraction = 0;
		// 	this.downScrollLimit = 0.85;
		// 	this.upScrollLimit = 0.0;
		// }
		for (i = end; i < chatWindowArr_.length; i++) {
			const newWindow = this.getBlankWindow();
			chatWindowArr_[i] = newWindow;
		}
		this.chatWindowArr = chatWindowArr_;
		this.setState({
			loading: false,
			chatWindows: chatWindowArr_,
		});
	};

	handleUpScroll = (newStart, newEnd) => {
		// console.log('Scrolling up...');
		var oldStart = this.currStart;
		var chatWindowArr_ = this.chatWindowArr;
		for (var i = newStart; i < oldStart; i++) {
			const contactId = this.contactKeys[i];
			if (
				this.state.showAssistanceRequired &&
				(this.chatKeys[contactId].callback === undefined || !this.chatKeys[contactId].callback)
			)
				continue;
			else if (
				this.state.showUnread &&
				(this.chatKeys[contactId].unread === undefined || this.chatKeys[contactId].unread === 0)
			)
				continue;
			var newWindow;
			if (this.state.listMode !== "2") {
				newWindow = this.getChatWindow(contactId, true);
			} else {
				newWindow = this.getKeywordWindow(contactId);
			}
			chatWindowArr_[i] = newWindow;
		}

		var oldEnd = this.currEnd;
		for (i = newEnd; i < oldEnd; i++) {
			const newWindow = this.getBlankWindow();
			chatWindowArr_[i] = newWindow;
		}

		this.currStart = newStart;
		this.currEnd = newEnd;
		this.chatWindowArr = chatWindowArr_;
		this.upScrollLimit = Math.max(0.1, this.currStart / this.chatWindowArr.length + 0.1);
		this.downScrollLimit = Math.max(0.1, this.currEnd / this.chatWindowArr.length);
		this.setState({
			chatWindows: chatWindowArr_,
		});
	};

	handleDownScroll = (newStart, newEnd) => {
		var oldStart = this.currStart;
		var chatWindowArr_ = this.chatWindowArr;
		for (var i = oldStart; i < newStart - 10; i++) {
			const newWindow = this.getBlankWindow();
			chatWindowArr_[i] = newWindow;
		}
		var oldEnd = this.currEnd;
		for (i = oldEnd; i < newEnd; i++) {
			const contactId = this.contactKeys[i];
			if (
				this.state.showAssistanceRequired &&
				(this.chatKeys[contactId].callback === undefined || !this.chatKeys[contactId].callback)
			)
				continue;
			else if (
				this.state.showUnread &&
				(this.chatKeys[contactId].unread === undefined || this.chatKeys[contactId].unread === 0)
			)
				continue;
			var newWindow;
			if (this.state.listMode !== "2") {
				newWindow = this.getChatWindow(contactId, true);
			} else {
				newWindow = this.getKeywordWindow(contactId);
			}
			if (i >= chatWindowArr_.length) chatWindowArr_.push(newWindow);
			else chatWindowArr_[i] = newWindow;
		}
		this.currStart = newStart;
		this.currEnd = newEnd;
		this.chatWindowArr = chatWindowArr_;
		this.upScrollLimit = Math.max(0.1, this.currStart / this.chatWindowArr.length + 0.1);
		this.downScrollLimit = Math.max(0.1, this.currEnd / this.chatWindowArr.length - 0.1);
		this.setState({
			chatWindows: chatWindowArr_,
		});
	};

	getChatWindow = (contactId, loadCard) => {
		if (this.chatKeys[contactId] === undefined) {
			return <BlankWindow />;
		}
		const chatId = this.chatKeys[contactId].chatId;
		const ts = this.chatKeys[contactId].timestamp;
		const currMenuId = this.state.menuId + (chatId || contactId);
		// const currMenuId = this.state.menuId + chatId;
		const newWindow = (
			<ChatWindowV2
				key={currMenuId}
				id={chatId}
				ts={ts}
				menuId={currMenuId}
				contactId={contactId}
				changeDashboardState={this.props.changeDashboardState}
				loadCard={loadCard}
			/>
		);
		return newWindow;
	};

	getKeywordWindow = (contactId) => {
		if (this.chatKeys[contactId] === undefined) {
			return <BlankWindow />;
		}
		const chatId = this.chatKeys[contactId].chatId;
		const name = this.chatKeys[contactId].name;
		const messageIndex = this.chatKeys[contactId].messageIndex;
		const messageProp = this.chatKeys[contactId].messageProp;
		const messageDateTime = this.chatKeys[contactId].messageDateTime;
		const newWindow = (
			<KeyWordChatWindow
				key={`${contactId}|${chatId}`}
				name={name}
				message={messageProp}
				messageDateTime={messageDateTime}
				contactId={contactId}
				chatId={chatId}
				messageIndex={messageIndex}
				changeDashboardState={this.props.changeDashboardState}
			/>
		);
		return newWindow;
	};

	getBlankWindow = () => {
		return <BlankWindow />;
	};

	modifyChatWindowList = () => {
		this.currStart = Math.min(this.currStart + 10, this.contactKeys.length - this.windowLimit);
		this.currEnd = this.currStart + this.windowLimit;
		this.addChatWindows(this.currStart, this.currEnd);
	};

	async getContact(id) {
		var contact = {};
		var currContactSnapshot = firebase.database().ref(`${Users}/${id}`);
		var snapshot = await currContactSnapshot.once("value");

		if (snapshot.exists()) {
			contact = snapshot.val();
		}
		return contact;
	}

	handleScroll = (e) => {
		const currFraction = e.target.scrollTop / e.target.scrollHeight;
		// const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
		if (currFraction > this.downScrollLimit && currFraction > this.fraction) {
			// console.log('Bottom:', currFraction);
			// this.modifyChatWindowList();
			const newStart = Math.min(this.currStart + 10, this.contactKeys.length - this.windowLimit);
			// this.currStart = Math.min(this.currStart + 20,
			//     this.contactKeys.length - this.windowLimit);
			const newEnd = newStart + this.windowLimit;
			// this.currEnd = this.currStart + this.windowLimit;
			this.handleDownScroll(newStart, newEnd);
		} else if (currFraction < this.upScrollLimit && currFraction < this.fraction) {
			if (this.currStart > 0) {
				const newStart = Math.max(0, this.currStart - 20);
				// this.currStart = Math.max(0, this.currStart - 20);
				// this.currEnd = this.currStart + this.windowLimit;
				const newEnd = newStart + this.windowLimit;
				this.handleUpScroll(newStart, newEnd);
			}
		}
		this.fraction = currFraction;
	};

	row = ({ index, style }) => {
		<div style={style}>Row {index}</div>;
	};

	render() {
		return (
			<>
				<Row style={{ padding: "1em", backgroundColor: "white" }} gutter={4}>
					<Col span={18}>
						<Input.Search
							prefix={
								<Select
									dropdownMatchSelectWidth={false}
									optionLabelProp="title"
									defaultValue="1"
									onChange={this.changeSearchMode}
									size="small"
									bordered={false}>
									<Select.Option title="N" value="1">
										Name
									</Select.Option>
									<Select.Option title="K" value="2">
										Keyword
									</Select.Option>
									<Select.Option title="P" value="3">
										Phone
									</Select.Option>
								</Select>
							}
							placeholder="Search Contact"
							onSearch={this.performAlgoliaSearch}
							allowClear
						/>
					</Col>
					<Col span={6}>
						<FilterBarCompo
							otherFilters={
								<>
									<Checkbox onChange={this.handleUnreadChange}>Unread</Checkbox>
									<Checkbox onChange={this.handleAssistanceRequiredChange}>
										Assistance Required
									</Checkbox>
								</>
							}
							uniqueTags={this.props.uniqueTags}
							filterSidebar={this.filterSidebar}
							filterByTags={this.filterByTags}
							adminNum={localStorage.getItem('phoneNum')}
						/>
					</Col>
				</Row>
				<Radio.Group defaultValue="all" onChange={this.handleRadioGroupChange} style={{ display: 'flex', width: '100%' }} >
					<Radio.Button style={{ flexGrow: 1, textAlign: 'center' }} value="all">All</Radio.Button>
					<Radio.Button style={{ flexGrow: 1, textAlign: 'center' }} value="open">Open</Radio.Button>
					<Radio.Button style={{ flexGrow: 1, textAlign: 'center' }} value="ongoing">Ongoing</Radio.Button>
					<Radio.Button style={{ flexGrow: 1, textAlign: 'center' }} value="closed">Closed</Radio.Button>
				</Radio.Group>
				<div className="card_container" onScroll={this.handleScroll}>
					{this.state.chatWindows}
				</div>
			</>
		);
	}
}

InfiniteList.contextType = myContext;

export default InfiniteList;
