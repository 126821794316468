import { React, useState} from 'react'
import { Button } from 'antd';
import firebase from 'firebase';
import { algoliaIndex } from "../Algolia.js";
import {
    open,
    ongoing,
    closed,
    v2_userChats,
    
    
  } from "../utils/contants"
export default function ConversationStatus(props) {

    const [loading, setLoading] = useState(false);

    const markAsClosed = () => {
        setLoading(true);
        let tags = props.chatObj.tags;
        if (!tags) tags = "";
        let tagsArr = tags.split('~');
        tagsArr = tagsArr.filter((tag) => tag !== `${open}` && tag !== `${ongoing}` && tag !== `${closed}`);
        tagsArr.push(`${closed}`);
        const tagString = tagsArr.join('~');
        const adminId = props.adminId;
        const contactId = props.contactId;
        firebase.database().ref(`${v2_userChats}/${adminId}/${contactId}/tags`).set(tagString);
        algoliaIndex
            .partialUpdateObject({
                objectID: props.chatId,
                tags: tagsArr,
            }).wait().then(() => {
                setLoading(false);
            })
    }

    return (
        <div>
            <Button 
                type="primary"
                shape="round"
                disabled={props.status === "closed"}
                loading={loading}
                onClick={() => markAsClosed()}>
                Close
            </Button>
        </div>
    )
}
