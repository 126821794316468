import React, { useState } from "react";
import { FileImageFilled, VideoCameraFilled, FilePdfFilled } from "@ant-design/icons";
import { Popover, Button } from "antd";
import { AiOutlinePaperClip } from "react-icons/ai";
import UploadModal from "./UploadModal";

export default function AttachmentPopup({ isAutoOn, adminNum, contact, ...passed }) {
	const [modalType, setModalType] = useState("none");

	const content = (
		<div>
			<div>
				<Button
					size="large"
					type="dashed"
					block
					onClick={() => setModalType("image")}
					style={{ marginBottom: "1em" }}>
					<FileImageFilled /> Image
				</Button>
			</div>
			<div>
				<Button
					size="large"
					type="dashed"
					block
					onClick={() => setModalType("video")}
					style={{ marginBottom: "1em" }}>
					<VideoCameraFilled /> Video
				</Button>
			</div>
			<div>
				<Button
					size="large"
					type="dashed"
					block
					onClick={() => setModalType("document")}
					style={{ marginBottom: "1em" }}>
					<FilePdfFilled /> PDF
				</Button>
			</div>
		</div>
	);
	return (
		<>
			{modalType !== "none" && (
				<UploadModal
					isVisible={modalType !== "none"}
					adminNum={adminNum}
					contact={contact}
					type={ modalType }
					{...passed}
					hideModal={() => setModalType("none")}
				/>
			)}
			<Popover placement="topLeft" content={content} title="Upload" trigger="click">
				<Button disabled={isAutoOn} className="icon-button">
					<AiOutlinePaperClip size={30} />
				</Button>
			</Popover>
		</>
	);
}
