import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Layout, Menu } from "antd";
import { MessageOutlined, PieChartOutlined, UserOutlined, MailOutlined } from "@ant-design/icons";
import firebase from "firebase";

const { Sider } = Layout;

export default function SidebarComponent({ adminId, selectedPage }) {
	const [collapsed, setCollapsed] = useState(false);
	const [numberOfContacts, setNumberOfContacts] = useState(null);
	const [selectedKey, setSelectedKey] = useState(window.location.pathname.split("/")[2]);

	function handleToggle() {
		setCollapsed((prevState) => !prevState);
	}

	useEffect(() => {
		setSelectedKey(selectedPage);
	}, [selectedPage])

	useEffect(() => {
		const path = "v2_userChats/" + adminId;
		if (adminId) {
			firebase
				.database()
				.ref(path)
				.on("value", (snapshot) => {
					setNumberOfContacts(snapshot.numChildren());
				});
		}
	}, [adminId]);

	return (
		<div style={{ width: "fit-content" }}>
			<Sider inlineCollapsed={collapsed} collapsible onCollapse={handleToggle} style={{ height: "100%" }}>
				<div style={{ color: "#4ea2ff", padding: "25px", paddingBottom: "10px" }}>Menu</div>
				<Menu
					title="Menu"
					theme="dark"
					selectedKeys={[selectedKey]}
					mode="inline"
					onSelect={({ key }) => {
						setSelectedKey(key);
					}}>
					<Menu.Item key="conversations" icon={<MessageOutlined />}>
						<Link to="/dashboard/conversations">Conversations</Link>
					</Menu.Item>
					<Menu.Item key="contacts" icon={<UserOutlined />} title="Contacts">
						<Link to="/dashboard/contacts">
							Contacts {numberOfContacts && <strong>({numberOfContacts})</strong>}
						</Link>
					</Menu.Item>
					{/* <Menu.Item key="analytics" icon={<PieChartOutlined />}>
						<Link to="/dashboard/analytics">Analytics</Link>
					</Menu.Item> */}
					<Menu.Item key="reports" icon={<PieChartOutlined />}>
						<Link to="/dashboard/reports">Reports</Link>
					</Menu.Item>
					<Menu.Item key="templates" icon={<MailOutlined />}>
						<Link to="/dashboard/templates">Templates</Link>
					</Menu.Item>
					<Menu.Item key="feedback" icon={<MailOutlined />}>
						<Link to="/dashboard/feedback">Feedback</Link>
					</Menu.Item>
				</Menu>
			</Sider>
		</div>
	);
}
