import React from 'react';
import '../css/reportSearchEntry.css';

export default function ReportSearchEntry({ name, phone, data, messageDateTime }) {
    return (
        <div className="entry-container">
            <span className="entry-name">{name}</span>
            <span className="entry-phone">{phone}</span>
            <div className="entry-content">{data}</div>
            <div className="entry-date-time">{messageDateTime}</div>
        </div>
    )
}