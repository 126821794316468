import React, { useState } from "react";
import { Button, Form, Input, Row, Col, Card } from "antd";
import { MailOutlined, LockOutlined } from "@ant-design/icons";
import firebase from "../Firebase";
import side_image from "../Images/left_side_image.jpg";
import logo from "../Images/logo.png";
import { useHistory } from "react-router-dom";

function Verification(props) {
	const [loading, setLoading] = useState(false);
    const history = useHistory();
    const Verification = async (email, password) => {
        try {
                const result = await firebase.auth().signInWithEmailAndPassword(email, password);
                if (result) {
                    return true;
                } else {
                    return false;
                }
            
        } catch (error) {
            alert(error.message);
            return false;
        }
    };
	const handleSubmit = async (values) => {
		setLoading(true);
		const isVerificationSuccessful = await Verification(values.email, values.password);
		if ( isVerificationSuccessful) {
            history.push('/reset')
		} else {
			alert("Verification Failed Please Try Again");
		}
	};

	return (
		<div className="main_form_container">
			<Card className="main_container_card">
				<div className="card_contents_form">
					<div className="side_image">
						<img id="side_image" src={side_image} alt="Test"></img>
					</div>
					<Form
						size="large"
						layout="vertical"
						name="Login"
						onFinish={handleSubmit}
						className="form-container">
						<div className="logo">
							<img id="logo" src={logo} alt="Logo"></img>
						</div>
						<div id="title_login">Verify Your Account</div>
						<br />
						<Form.Item
							name="email"
							rules={[
								{
									required: true,
									message: "Please input your E-Mail!",
								},
								{
									type: "email",
									message: "Please enter a valid email",
								},
							]}>
							<Input placeholder="E-Mail" addonBefore={<MailOutlined />} />
						</Form.Item>
						<br />
						<Form.Item
							name="password"
							rules={[
								{
									required: true,
									message: "Please input your password!",
								},
								{
									type: "string",
									min: 8,
									message: "Password must be at least 8 characters long"
								},
							]}>
							<Input.Password placeholder="Password" addonBefore={<LockOutlined />} />
						</Form.Item>

						<Row justify="end" align="middle" style={{ paddingTop: "2em" }}>
							<Col span={12} style={{ textAlign: "right" }}>
								<Form.Item style={{ marginBottom: "0px" }}>
									<Button
										style={{ width: "100%" }}
										type="primary"
										size="large"
										htmlType="submit"
										loading={loading}>
										Verify
									</Button>
								</Form.Item>

							</Col>
						</Row>
					</Form>
				</div>
			</Card>
		</div>
	);
}

export default Verification;
