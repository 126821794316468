import React, { Component, createRef } from "react";
import firebase from "../Firebase.js";
import ChatWindowCard from "./ChatWindowCard";
import SkeletonCard from "./Skeleton";
import { algoliaIndex } from "../Algolia.js";
import { myContext } from "./myContext";
import { Menu, Modal } from "antd";

import { ASSIGNED_TO, CONTACT_SELECTED, IMPORTANT_CHANGED, TAGS_CHANGED } from "../utils/amplitudeEvents";
import { sendAmplitudeData } from "../utils/amplitudeFunctions";
import { Dropdown } from "antd";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import {
	Chats,
	v2_userChats,
	v2_agentWiseChats,
	v2_assignedChats,
	Users,
	chatMessages,
	
  } from "../utils/contants"
const { confirm } = Modal;
export class ChatWindowV2 extends Component {
	static contextType = myContext;
	constructor(props) {
		super(props);

		this.mounted = false;
		this.unreadMessages = [];
		this.count = 0;
		this.alert = false;
		this.lastMessageTimestamp = "";
		this.state = {
			loading: true,
			contactLoaded: false,
			contact: {},
			chatObj: {},
			contactName: "",
			chats: [],
			count: 0,
			attendedBy: "",
			attendedById: "",
			alert: false,
			tags: "",
			lastMessageDate: "",
			lastMessageTime: "",
			lastMessage: "",
			randomKey: Math.random()
		};
		this.cardRef = createRef();
	}

	componentDidMount() {
		this.mounted = true;
		if (this.props.loadCard) {
			this.addContactListener(this.props.contactId);
			// this.addChatListener(this.props.id);
			this.addChatListenerV2(this.props.id);
			this.addLastMessageListener(this.props.id);
		} else {
			this.setState({
				loading: true,
			});
		}
	}

	componentWillUnmount() {
		this.mounted = false;
	}

	addLastMessageListener = (chatId) => {
		firebase
			.database()
			.ref(`${Chats}/${chatId}`)
			.on("value", (snapshot) => {
				const msgBody = snapshot.val();
				if (msgBody !== null) {
					this.lastMessageTimestamp = msgBody.timestamp;
					var currMessage = msgBody.lastMessageSent;
					if (currMessage?.startsWith("https://")) currMessage = "Media";
					const dateObj = new Date(msgBody.timestamp * 1000);
					const currDate = dateObj.getDate() + "-" + (dateObj.getMonth() + 1) + "-" + dateObj.getFullYear();
					const hour = dateObj.getHours() < 10 ? "0" + dateObj.getHours() : dateObj.getHours();
					const minute = dateObj.getMinutes() < 10 ? "0" + dateObj.getMinutes() : dateObj.getMinutes();
					const currTime = hour + ":" + minute;
					this.setState({
						lastMessage: currMessage,
						lastMessageDate: currDate,
						lastMessageTime: currTime,
					});
				}
				// this.props.sendLastMessageTimestamp(chatId, this.lastMessageTimestamp);
			});
	};

	addContactListener = (id) => {
		firebase
			.database()
			.ref(`${Users}/${id}`)
			.on("value", (snapshot) => {
				if (this.mounted && snapshot.exists()) {
					const contact_ = snapshot.val();
					this.setState({
						contact: contact_,
						contactName: contact_.userDisplayName,
						contactLoaded: true,
					});
				}
			});
	};

	addChatListenerV2 = (chatId) => {
		firebase
			.database()
			.ref(`${v2_userChats}/${this.context.adminId}/${this.props.contactId}`)
			.on("value", (snapshot) => {
				const curr = snapshot.val();
				if (curr) {
					var unread = curr.unread;
					var assistanceRequired = curr.callback;
					if (unread === undefined) unread = 0;
					if (assistanceRequired === undefined) assistanceRequired = false;
					this.setState({
						loading: false,
						count: unread,
						alert: assistanceRequired,
						tags: curr.tags ?? "",
						attendedBy: curr.attendedBy,
						attendedById: curr.attendedById,
						chatObj: curr
					});
				}
			});
	};

	addUnreadMessageListener = (chatId, msgId) => {
		var msgRef = firebase.database().ref(`${chatMessages}/${chatId}/${msgId}`);
		msgRef.on("value", (snapshot) => {
			const msgBody = snapshot.val();
			if (msgBody.read === "true") {
				this.count = 0;
				this.unreadMessages = [];
				this.alert = false;
				msgRef.off("value");
				if (this.mounted) {
					this.setState({
						count: 0,
					});
				}
				// this.sendCountData(chatId, 0);
			}
		});
	};

	markUnreadMessages = () => {
		// var unreadList = this.unreadMessages.slice();
		// const chatId = this.props.id;
		// for (var i = 0; i < unreadList.length; i++) {
		//     const currId = unreadList[i];
		//     this.markMessageAsRead(chatId, currId);
		// }
		if (!this.state.loading) {
			firebase.database().ref(`${v2_userChats}/${this.context.adminId}/${this.props.contactId}`).update({
				unread: 0,
				callback: false,
			});
			if (this.state.attendedById !== "") {
				firebase.database().ref(`${v2_assignedChats}/${this.context.adminId}/${this.props.contactId}`).update({
					unread: 0,
					callback: false,
				});
				firebase.database().ref(`${v2_agentWiseChats}/${this.state.attendedById}/${this.props.contactId}`).update({
					unread: 0,
					callback: false,
				});
			}
			algoliaIndex.partialUpdateObject({
				objectID: this.props.id,
				unread: false,
				assistanceRequired: false
			});
		}
	};

	markMessageAsRead = (chatId, id) => {
		firebase.database().ref(`${chatMessages}/${chatId}/${id}`).update({ read: "true" });
	};

	assignUserToAdmin = (adminName, adminID_, department) => {
		sendAmplitudeData(ASSIGNED_TO, { name: adminName });
		var obj = this.state.chatObj;
		var attendedByName = adminName;
		if (department) {
			attendedByName += " - " + department;
		}
		obj['attendedById'] = adminID_;
		obj['attendedBy'] = attendedByName;
		obj['auto_reply'] = 'false';
		obj['auto_reply_ts'] = Math.round(Date.now() / 1000).toString();
		firebase.database().ref(`${v2_agentWiseChats}/${this.state.attendedById}/${this.props.contactId}`).set(null);
		firebase.database().ref(`${v2_agentWiseChats}/${adminID_}/${this.props.contactId}`).set(obj);
		firebase.database().ref(`${v2_assignedChats}/${this.context.adminId}/${this.props.contactId}`).set(obj);
		firebase.database().ref(`${v2_userChats}/${this.context.adminId}/${this.props.contactId}`).set(obj);
		algoliaIndex.partialUpdateObject({
			'objectID': this.props.id,
			'attendedById': adminID_
		});
		this.setState({
			attendedBy: attendedByName,
			attendedById: adminID_,
		});
	};

	handleCardClick = (e) => {
		if (e.target !== undefined) {
			if (this.cardRef && this.cardRef.current.contains(e.target)) {
				if (
					e.target.className !==
					"MuiButtonBase-root MuiListItem-root MuiMenuItem-root MuiMenuItem-gutters MuiListItem-gutters MuiListItem-button"
				) {
					if (this.state.contactLoaded) {
						this.props.changeDashboardState(this.state.contact, this.props.id);
						this.markUnreadMessages();
						this.count = 0;
						this.unreadMessages = [];
						this.alert = false;
						if (this.mounted) {
							this.setState({
								count: 0,
								alert: false,
							});
						}
						sendAmplitudeData(CONTACT_SELECTED, {
							contactNumber: this.state.contact.userPhone,
						});
					}
					// this.sendCountData(this.props.id, 0);
				}
			}
		}
	};

	toggleImportantTag = () => {
		let tagString = this.state.tags;

		if (/~?Important~?/.test(tagString)) {
			this.removeTag("Important");
			sendAmplitudeData(IMPORTANT_CHANGED, {
				type: "unmarked",
			});
		} else {
			sendAmplitudeData(IMPORTANT_CHANGED, {
				type: "marked",
			});
			tagString = tagString + "~Important";
			firebase.database().ref(`${v2_userChats}/${this.context.adminId}/${this.props.contactId}/tags`).set(tagString);
			this.setState({ tags: tagString });
		}
	};

	sendCountData = (chatId, count) => {
		this.props.countFunction(chatId, count);
	};

	showDeleteModal = (tag) => {
		const removeTag = (tag) => {
			console.log('Removing tag...');
			const tagString = this.state.tags;
			const tags = tagString.split("~");
			const index = tags.indexOf(tag);
			if (index > -1) {
				if (tags[index].toLowerCase() !== "important") {
					sendAmplitudeData(TAGS_CHANGED, {
						type: "removed",
						value: tags[index],
					});
				} else {
					sendAmplitudeData(IMPORTANT_CHANGED, {
						type: "unmarked",
					});
				}
				tags.splice(index, 1);
				const updatedTagString = tags.join("~");
				firebase
					.database()
					.ref(`${v2_userChats}/${this.context.adminId}/${this.props.contactId}/tags`)
					.set(updatedTagString);
				algoliaIndex
					.partialUpdateObject({
						objectID: this.props.id,
						tags: tags,
					})
				this.setState({ tags: updatedTagString });
			}
		};

		const renderCard = () => {
			this.setState({ randomKey: Math.random() });
		}

		confirm({
			title: 'Are you sure you want to remove this tag?',
			icon: <ExclamationCircleOutlined />,
			okText: 'Yes',
			cancelText: 'No',
			okType: 'danger',
			onOk() {
				removeTag(tag);
			},
			onCancel() {
				renderCard();
			}
		});
	}

	// removeTag = (tag) => {
	// 	console.log('Removing tag...');
	// 	const tagString = this.state.tags;
	// 	const tags = tagString.split("~");
	// 	const index = tags.indexOf(tag);
	// 	if (index > -1) {
	// 		if (tags[index].toLowerCase() !== "important") {
	// 			sendAmplitudeData(TAGS_CHANGED, {
	// 				type: "removed",
	// 				value: tags[index],
	// 			});
	// 		} else {
	// 			sendAmplitudeData(IMPORTANT_CHANGED, {
	// 				type: "unmarked",
	// 			});
	// 		}
	// 		tags.splice(index, 1);
	// 		const updatedTagString = tags.join("~");
	// 		firebase
	// 			.database()
	// 			.ref(`v2_userChats/${this.context.adminId}/${this.props.contactId}/tags`)
	// 			.set(updatedTagString);
	// 		this.setState({ tags: updatedTagString });
	// 	}
	// };

	getAdminDispayName = (name, department) => {
		let displayName = name;
		if (department) {
			displayName += ' - ' + department;
		}
		return displayName;
	}

	render() {
		var isActive =
			Object.keys(this.context.selectedContact).length !== 0 &&
			this.context.selectedContact.id === this.props.contactId;
		if (!this.state.contactLoaded) {
			return (
				<div className="skele_card">
					<SkeletonCard />
				</div>
			);
		} else {
			if (this.state.contactName === undefined) {
				return (
					<div className="skele_card">
						<SkeletonCard />
					</div>
				);
			}
			var chatInfo = {
				contactName: this.state.contactName,
				lastMessage: this.state.lastMessage,
				lastMessageTime: this.state.lastMessageTime,
				lastMessageDate: this.state.lastMessageDate,
			};
			return (
				<Dropdown
					overlay={
						<Menu mode="vertical">
							<Menu.Item onClick={this.toggleImportantTag} key="toggle-important">
								{/~?Important~?/.test(this.state.tags) ? "Unmark" : "Mark"} Important
							</Menu.Item>
							<Menu.SubMenu key="assign-to" title="Assign to">
								{this.context.adminList.map(({ uid, name, department }) => (
									<Menu.Item key={uid} onClick={() => this.assignUserToAdmin(name, uid, department)}>
										{this.getAdminDispayName(name, department)}
									</Menu.Item>
								))}
							</Menu.SubMenu>
						</Menu>
					}
					trigger={["contextMenu"]}>
					<div ref={this.cardRef} style={{ cursor: "context-menu" }} onClick={this.handleCardClick}>
						<ChatWindowCard
							key={this.state.randomKey}
							assistanceRequired={this.state.alert}
							isActive={isActive}
							contactId={this.props.contactId}
							chatInfo={chatInfo}
							count={this.state.count}
							chatID={this.props.id}
							attendedBy={this.state.attendedBy}
							tags={this.state.tags}
							onCloseTags={this.showDeleteModal}
						/>
					</div>
				</Dropdown>
			);
		}
	}
}
ChatWindowV2.contextType = myContext;

export default ChatWindowV2;
